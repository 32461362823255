import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import * as timeago from 'timeago.js';
import notification from '../img/notification.png'; // Import the image

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;

const PopupContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  width: 90vw;
  max-width: 400px;
  height: 60vh;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};

  @media (min-width: 768px) {
    width: 30vw;
  }

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 20px;
    border-width: 0 10px 10px 10px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
    z-index: -1;
  }
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  max-height: calc(100% - 60px);
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 18px;
  color: #333;
`;

const Tabs = styled.div`
  display: flex;
  margin-top: 10px;
`;

const Tab = styled.div`
  flex: 1;
  padding: 5px 0;
  text-align: center;
  cursor: pointer;
  border-bottom: 2px solid ${(props) => (props.active ? '#00bfa5' : 'transparent')};
  color: ${(props) => (props.active ? '#00bfa5' : '#333')};
`;

const MarkAllReadButton = styled.button`
  background-color: #00bfa5;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 5px;
  cursor: pointer;
  margin: 7px 0;
`;

const NotificationList = styled.div`
  margin-top: 8px;
`;

const NotificationItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const NotificationText = styled.p`
  margin: 0;
  font-size: 14px;
  color: #555;
`;

const NotificationTime = styled.span`
  font-size: 12px;
  color: #999;
`;

const UnreadIndicator = styled.div`
  width: 10px;
  height: 10px;
  background-color: #00bfa5;
  border-radius: 50%;
  margin-left: 10px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #333;
  font-size: 1.5rem;
  cursor: pointer;
`;

const NoNotificationsContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const NoNotificationsImage = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
`;

const Notifications = ({ isOpen, onClose }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeTab, setActiveTab] = useState('Unread');

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        axios.defaults.withCredentials = true;
        const response = await axios.get('/notifications/user', {
          withCredentials: true,
        });
        if (response.data.message) {
          setErrorMessage(response.data.message);
        } else {
          setNotifications(response.data);
        }
      } catch (err) {
        setErrorMessage(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen]);

  const markAllAsRead = async () => {
    try {
      await axios.put('/notifications/markAllAsRead', {}, {
        withCredentials: true,
      });
      // Update the notifications state to remove unread notifications
      setNotifications(notifications.map(notification => ({
        ...notification,
        read: true,
      })));
      // Filter out unread notifications after marking them as read
      setNotifications(notifications.filter(notification => notification.read));
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  const markAsRead = async (id) => {
    try {
      await axios.put(`/notifications/${id}/read`, {}, {
        withCredentials: true,
      });
      // Update the specific notification in the state
      setNotifications(notifications.map(notification => 
        notification._id === id ? { ...notification, read: true } : notification
      ));
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  const filteredNotifications = notifications.filter(notification =>
    activeTab === 'Unread' ? !notification.read : true
  );

  return (
    <>
      <Overlay isOpen={isOpen} />
      <PopupContainer isOpen={isOpen}>
        <ModalContent>
          <Header>
            <Title>Notifications</Title>
            <CloseButton onClick={onClose}>&times;</CloseButton>
          </Header>
          <Tabs>
            <Tab active={activeTab === 'Unread'} onClick={() => setActiveTab('Unread')}>
              Unread
            </Tab>
            <Tab active={activeTab === 'All'} onClick={() => setActiveTab('All')}>
              All
            </Tab>
          </Tabs>
          {activeTab === 'Unread' && filteredNotifications.length > 0 && (
            <MarkAllReadButton onClick={markAllAsRead}>Mark all as read</MarkAllReadButton>
          )}
          {loading && <NotificationText>Loading...</NotificationText>}
          {!loading && errorMessage && <NotificationText>{errorMessage}</NotificationText>}
          {!loading && !errorMessage && filteredNotifications.length === 0 && (
            <NoNotificationsContainer>
              <NoNotificationsImage src={notification} alt="No notifications" />
              <NotificationText>No unread notifications</NotificationText>
            </NoNotificationsContainer>
          )}
          {!loading && !errorMessage && filteredNotifications.length > 0 && (
            <NotificationList>
              {filteredNotifications.map((notification) => (
                <NotificationItem key={notification._id} onClick={() => markAsRead(notification._id)}>
                  <Avatar src={notification.userAvatar} alt="User Avatar" />
                  <NotificationContent>
                    <NotificationText>{notification.message}</NotificationText>
                    <NotificationTime>{timeago.format(notification.createdAt)}</NotificationTime>
                  </NotificationContent>
                  {!notification.read && <UnreadIndicator />}
                </NotificationItem>
              ))}
            </NotificationList>
          )}
        </ModalContent>
      </PopupContainer>
    </>
  );
};

export default Notifications;
