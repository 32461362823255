
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import ChannelImage from "../img/ChannelImage.jpeg";
import VideoEditModal from "./VideoEditModal"; // Assuming the path to VideoEditModal


// Axios configuration to include credentials
axios.defaults.withCredentials = true;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden; /* Prevent scrolling */
`;

const PopupContent = styled.div`
  background: #333;
  padding: 4vh;
  border-radius: 1vh;
  width: 40vw;
  color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 2vh;
  margin: 2vh 0;
  border-radius: 1vh;
  border: 0.1vh solid #555;
  background: #333;
  color: white;
`;

const Button = styled.button`
  padding: 2vh 4vh;
  margin-top: 2vh;
  border: none;
  border-radius: 1vh;
  background: #555;
  color: white;
  cursor: pointer;

  &:hover {
    background: #777;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 4vh;
`;

const EditProfilePopup = ({ channelData, onClose, onSave }) => {
  const [name, setName] = useState(channelData.name || "");
  const [description, setDescription] = useState(channelData.description || "");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Disable scrolling when popup is open
    document.body.style.overflow = "hidden";
    return () => {
      // Re-enable scrolling when popup is closed
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `/channels/edit/${channelData._id}`,
        { name, description }
      );
      onSave(response.data);
      onClose();
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleEditSuccess = (updatedChannel) => {
    onSave(updatedChannel);
    setIsModalOpen(false);
  };

  return (
    <>
      <PopupOverlay>
        <PopupContent>
          <h2>Edit Profile</h2>
          <img
            src={ChannelImage}
            alt="Default Avatar"
            style={{
              width: "20vw",
              height: "20vw",
              borderRadius: "50%",
              marginBottom: "2vh",
            }}
          />
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Channel Name"
          />
          <Input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
          />
          <ButtonContainer>
            <Button onClick={handleSave}>Save</Button>
            <Button onClick={onClose} style={{ background: "#444" }}>
              Cancel
            </Button>
          </ButtonContainer>
        </PopupContent>
      </PopupOverlay>

      {isModalOpen && (
        <VideoEditModal
          video={channelData}
          onCancel={() => setIsModalOpen(false)}
          onEditSuccess={handleEditSuccess}
        />
      )}
    </>
  );
};

export default EditProfilePopup;
