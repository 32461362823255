import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "../components/Card";
import TechContainer from "../components/TechContainer";
import HomeContainer from "../components/HomeContainer";
import SportsContainer from "../components/SportsContainer";
import ShortsCard from "../components/ShortsCard";
import axios from "axios";
import Slideshow from "../components/SlideShow";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import HomeSkeletonLoader from "../components/HomeSkeletonLoader"; // Import HomeSkeletonLoader

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
  margin: 0;
  overflow-y: auto;
  width: 98.79vw;
  padding-top: 2vh;

  /* Webkit browsers */
  &::-webkit-scrollbar {
    width: 0.4vw;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 0.4vw;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: gray transparent;
`;

const SlideshowContainer = styled.div`
  width: 100%;
  height: 80vh;
  z-index: 0;

  @media (max-width: 768px) {
    height: 50vh;
  }
`;

const SectionTitle = styled.h1`
  font-size: 2.5vw;
  padding-top: 3vh;
  padding-bottom: 2vh;
  text-align: center;
  color: black;

  @media (max-width: 768px) {
    font-size: 4vw;
  }
`;

const Title = styled.h1`
  font-size: 2.5vw;
  text-align: center;
  color: black;

  @media (max-width: 768px) {
    font-size: 4vw;
  }
`;

const CategoryContainer = styled.div`
  display: flex;
  gap: 1.2vw;
  margin-top: 1.8vh;
  margin-bottom: 1vh;
  z-index: 2;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    display:none;
  }
`;

const VideoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2vw;
  margin: 1vw 0;
  z-index: 2;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ShortsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1vw;
  flex-wrap: wrap;
  margin: 2vw 0;
  z-index: 2;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

const Home = ({ type, theme }) => {
  const [videos, setVideos] = useState([]);
  const [shorts, setShorts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const res = await axios.get(`/videos/${type}`);
        setVideos(res.data);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    const fetchShorts = async () => {
      try {
        const res = await axios.get("/shorts/random");
        setShorts(res.data);
      } catch (error) {
        console.error('Error fetching shorts:', error);
      }
    };
    console.log('Fetching videos and shorts...');
    fetchVideos();
    fetchShorts();
  }, [type]);

  useEffect(() => {
    if (videos.length > 0 && shorts.length > 0) {
      setLoading(false);
    }
  }, [videos, shorts]);

  const slides = [
    { url: "https://m.media-amazon.com/images/I/71EinHErCtL._SX3000_.jpg" },
    { url: "https://m.media-amazon.com/images/I/71NqG9bBp7L._SX3000_.jpg" },
    { url: "https://m.media-amazon.com/images/I/61lwJy4B8PL._SX3000_.jpg" },
    { url: "https://m.media-amazon.com/images/I/71Ie3JXGfVL._SX3000_.jpg" },
    { url: "https://m.media-amazon.com/images/I/71EinHErCtL._SX3000_.jpg" },
    { url: "https://m.media-amazon.com/images/I/71NqG9bBp7L._SX3000_.jpg" },
  ];

  if (loading) {
    return <HomeSkeletonLoader />;
  }

  return (
    <Container theme={theme}>
      <SlideshowContainer>
        <Slideshow slides={slides} />
      </SlideshowContainer>
      <SectionTitle>Categories</SectionTitle>
      <CategoryContainer>
        <TechContainer />
        <HomeContainer />
        <SportsContainer />
      </CategoryContainer>
      <SectionTitle>
        <TrendingUpIcon 
          sx={{
            paddingRight: '1vw',
            color: '#cb4335',
            fontSize: '4vh',
          }}
        />
        Popular Reviews
      </SectionTitle>
      <VideoContainer>
        {videos.slice(0, 3).map((video) => (
          <Card key={video._id} video={video} />
        ))}
      </VideoContainer>
      <Title>Shorts</Title>
      <ShortsContainer>
        {shorts.slice(0, window.innerWidth <= 768 ? 4 : 5).map((short) => (
          <ShortsCard key={short._id} short={short} />
        ))}
      </ShortsContainer>
    </Container>
  );
};

export default Home;
