import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import ChannelImage from "../img/ChannelImage.jpeg";
import Card2 from "../components/Card2";
import ShortsCard from "../components/ShortsCard";
import EditProfilePopup from "../components/EditProfilePopup";

// Styles for the profile section
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333;
  padding-top: 2px;
  min-height: 100vh;
  width: 100%;
  padding: 2px;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal scrolling */

  @media (max-width: 768px) {
    padding-top: 60px;
    padding-bottom: 50px;
  }
`;

const DefaultAvatarIcon = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-bottom: 20px;
  background-image: url(${ChannelImage});
  background-size: cover;
  cursor: pointer;
`;

const UserDetails = styled.div`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const ChannelName = styled.span`
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  font-size: 23px;
`;

const Description = styled.p`
  color: #666;
  display: block;
  margin-bottom: 5px;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin: 20px 0;
  color: white;
  text-align: center;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const Stat = styled.div`
  flex: 1;
  padding: 5px;
`;

const StatLabel = styled.div`
  font-size: 14px;
  color: #bbb;
`;

const StatValue = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const SeparatorLine = styled.hr`
  width: 100%;
  border: 0;
  border-top: 2px solid #555;
  margin: 0px 0;
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
`;

const TabButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-bottom: ${(props) => (props.active ? "1px solid #ffffff" : "none")};
  flex: 1;
  text-align: center;
  padding: 0 10px; /* Adjust padding to reduce the border width */
  width: auto; /* Ensure the button does not stretch */
  display: inline-block; /* Inline-block to shrink the width */
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1200px; /* Adjust the max-width as needed */
  box-sizing: border-box;
  padding: 0 20px; /* Add some padding to avoid touching the edges */
`;

const VideoContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Change to wrap to avoid overflow */
  justify-content: flex-start; /* Center the items */
  gap: 20px;
  margin-top: 20px;
`;

const HistoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ gap }) => gap || "15px"};
  margin-top: 20px;
  width: 100%;
`;

const ErrorMessage = styled.p`
  color: white;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
`;

const ChannelProfile = () => {
  const [channelData, setChannelData] = useState({});
  const [expandedDescription, setExpandedDescription] = useState(false);
  const [activeTab, setActiveTab] = useState("videos");
  const [videos, setVideos] = useState([]);
  const [shorts, setShorts] = useState([]);
  const [isEditPopupOpen, setEditPopupOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChannelDetails = async () => {
      setLoading(true);
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (!userData || !userData.ownedChannel) {
          throw new Error("Channel ID not found in local storage");
        }
        const channelId = userData.ownedChannel;
        const response = await axios.get(`/channels/details/${channelId}`);
        const { description, ...restData } = response.data;
        const channelDataWithDescription = { ...restData, description };
        
        setChannelData(channelDataWithDescription);
      } catch (error) {
        console.error("Error fetching channel details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchChannelDetails();
  }, []);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const channelId = userData.ownedChannel;
        const response = await axios.get(`/videos/${channelId}`);
        setVideos(response.data);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    const fetchShorts = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const channelId = userData.ownedChannel;
        const response = await axios.get(`/shorts/${channelId}`);
        setShorts(response.data);
      } catch (error) {
        console.error("Error fetching shorts:", error);
      }
    };

    if (activeTab === "videos") {
      fetchVideos();
    } else {
      fetchShorts();
    }
  }, [activeTab]);

  const toggleDescription = () => {
    setExpandedDescription(!expandedDescription);
  };

  const handleAvatarClick = () => {
    setEditPopupOpen(true);
  };

  const handleClosePopup = () => {
    setEditPopupOpen(false);
  };

  const handleSaveProfile = (updatedData) => {
    setChannelData(updatedData);
  };

  const handleVideoDeleted = (videoId) => {
    setVideos((prevVideos) => prevVideos.filter((video) => video._id !== videoId));
  };

  return (
    <Container>
      <DefaultAvatarIcon onClick={handleAvatarClick} />
      <UserDetails>
        <ChannelName>{channelData.name}</ChannelName>
        <Description>{channelData.description}</Description>
      </UserDetails>
      <StatsContainer>
        <Stat>
          <StatLabel>Subscribers</StatLabel>
          <StatValue>{channelData.subscribers?.length || 0}</StatValue>
        </Stat>
        <Stat>
          <StatLabel>Videos</StatLabel>
          <StatValue>{videos?.length || 0}</StatValue>
        </Stat>
        <Stat>
          <StatLabel>Shorts</StatLabel>
          <StatValue>{shorts?.length || 0}</StatValue>
        </Stat>
        <Stat>
          <StatLabel>Likes</StatLabel>
          <StatValue>{channelData.likes || 0}</StatValue>
        </Stat>
      </StatsContainer>
      <SeparatorLine />
      <TabsContainer>
        <TabButton
          active={activeTab === "videos"}
          onClick={() => setActiveTab("videos")}
        >
          Videos
        </TabButton>
        <TabButton
          active={activeTab === "shorts"}
          onClick={() => setActiveTab("shorts")}
        >
          Shorts
        </TabButton>
      </TabsContainer>
      <ContentContainer>
        {activeTab === "videos" && (
          <VideoContainer gap="20px">
            {videos.map((video) => (
              <Card2 key={video._id} video={video} />
            ))}
          </VideoContainer>
        )}
        {activeTab === "shorts" && (
          <VideoContainer gap="10px">
            {shorts.map((short) => (
              <ShortsCard key={short._id} short={short} />
            ))}
          </VideoContainer>
        )}
      </ContentContainer>
      {isEditPopupOpen && (
        <EditProfilePopup
          channelData={channelData}
          onClose={handleClosePopup}
          onSave={handleSaveProfile}
        />
      )}
      {!loading && !channelData.name && (
        <ErrorMessage>Channel not found. Please try again later.</ErrorMessage>
      )}
    </Container>
  );
};

export default ChannelProfile;
