import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Card from "../components/Card";

const ProfileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color:#333;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.textSoft};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.bgLighter};
    border-radius: 10px;
  }

  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.textSoft} ${({ theme }) => theme.bgLighter};
`;

const CardsContainer = styled.div`
  margin-left: 30px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 7vw;
  gap: 20px;
`;

const Message = styled.p`
  margin: 20px;
  font-size: 14px;
  color: ${({ theme }) => theme.text};
`;

const MyVideos = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (!userData || !userData._id) {
          throw new Error("User ID not found in local storage");
        }
        const userId = userData._id;
        console.log("User ID:", userId);
        const endpoint = `/videos/${userId}/user-videos`;
        const response = await axios.get(endpoint);
    
        if (Array.isArray(response.data)) {
          setVideos(response.data);
        } else if (response && response.data && response.data.message) {
          setErrorMessage(response.data.message);
        } else {
          throw new Error("Invalid data format received from server");
        }
      } catch (error) {
        console.error("Error fetching videos:", error);
        setErrorMessage("Error fetching videos. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <ProfileContainer>
      {errorMessage ? (
        <Message>{errorMessage}</Message>
      ) : (
        <CardsContainer>
          {videos.map((video) => (
            <Card
              key={video._id}
              video={video}
            />
          ))}
        </CardsContainer>
      )}
    </ProfileContainer>
  );
};

export default MyVideos;
