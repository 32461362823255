import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Card from "../components/Card";
import SkeletonLoader from "../components/SkeletonLoader"; // Import SkeletonLoader

const SubscriptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.bg};
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 20px; /* Add padding to the container */

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.textSoft};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.bgLighter};
    border-radius: 10px;
  }

  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.textSoft} ${({ theme }) => theme.bgLighter};
`;

const CardsWrapper = styled.div`
  margin-left: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 7vw;
`;

const Message = styled.p`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: white;
  font-weight: bold;
`;

const SubscriptionPage = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (!userData || !userData._id) {
          throw new Error("User ID not found in local storage");
        }
        const userId = userData._id;

        const endpoint = `/videos/${userId}/subscribed-videos`;
        const response = await axios.get(endpoint);

        if (Array.isArray(response.data)) {
          setSubscriptions(response.data);
        } else if (response && response.data && response.data.message) {
          setErrorMessage(response.data.message);
        } else {
          throw new Error("Invalid data format received from server");
        }
      } catch (error) {
        console.error("Error fetching subscription data:", error);
        if (error.response) {
          console.log("Server response:", error.response.data);
          setErrorMessage(error.response.data.message || "Error fetching subscription data. Please try again later.");
        } else {
          setErrorMessage("Error fetching subscription data. Please try again later.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  if (loading) {
    return <SkeletonLoader />;
  }

  return (
    <SubscriptionContainer>
      {errorMessage ? (
        <Message>{errorMessage}</Message>
      ) : (
        <CardsWrapper>
          {subscriptions.map((video) => (
            <Card key={video._id} video={video} />
          ))}
        </CardsWrapper>
      )}
    </SubscriptionContainer>
  );
};

export default SubscriptionPage;
