import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import VideoCallOutlinedIcon from "@mui/icons-material/VideoCallOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import axios from "axios";
import LamaTube from "../img/logo.png";
import SearchBar from "./SearchBar";
import Menu from "./Menu";
import Notifications from "./Notifications";

const Container = styled.div`
  position: fixed;
  padding-top: 1vh;
  padding-bottom: 1vh;
  left: 0;
  width: 100vw;
  background-color: #333;
  height: 6vh;
  z-index: 1000;

  @media (max-width: 768px) {
    height: 3vh;
    padding-top: 1.3vh;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 1000;
  position: relative;
`;

const LogoImage = styled.img`
  margin-right: 1vw;
  width: 3vw;
  height: 4.3vh;
  margin-left: 1vw;

  @media (max-width: 768px) {
    display: none;
  }
`;

const LogoText = styled.span`
  font-weight: bold;
  color: #cb4335;
  margin-left: 1vw;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MenuIconStyled = styled(IconButton)`
  && {
    color: ${({ theme }) => theme.text};
    margin-left: 2vw;

    &:hover {
      background-color: transparent;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const User = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 1vw;
  gap: 0.4vw;
  font-weight: 500;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
`;

const Avatar = styled.img`
  width: 6vw;
  height: 6vw;
  border-radius: 10%;
  background-color: #999;
  margin-left: 2vw;

  @media (max-width: 768px) {
    width: 5vw;
    height: 5vw;
  }
`;

const DefaultAvatarIcon = styled(AccountCircleOutlinedIcon)`
  && {
    color: #e5e5e5;
    padding-right: 0.7vw;
    font-size: 2.7vw;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const ButtonStyled = styled(Link)`
  padding: 0.5vw 2vw;
  background-color: #cb4335;
  border: 1.5px solid transparent;
  border-radius: 2vw;
  color: #fcfcfc;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: flex;
  align-items: center;
  text-align: center;

  &:hover {
    background-color: #f15c4f;
    color: #fcfcfc;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const { currentUser } = useSelector((state) => state.user);
  const dropdownRef = useRef(null);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const fetchUnreadCount = async () => {
      try {
        const response = await axios.get("/notifications/unread", {
          withCredentials: true,
        });
        setUnreadCount(response.data.unreadCount);
      } catch (error) {
        console.error("Error fetching unread notifications count:", error);
      }
    };

    if (currentUser) {
      fetchUnreadCount();
    }
  }, [currentUser]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  return (
    <Container>
      <Wrapper>
        <MenuIconStyled onClick={toggleMenu}>
          <MenuIcon />
        </MenuIconStyled>
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          <LogoImage src={LamaTube} alt="Logo" />
        </Link>
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          <LogoText>FRIXOS</LogoText>
        </Link>

        <SearchBar />

        {!currentUser ? (
          <div style={{ marginLeft: "auto", marginRight: "5vw" }}>
            <ButtonStyled to="/signin">Sign in</ButtonStyled>
          </div>
        ) : (
          <User>
            <ButtonStyled to={currentUser.ownedChannel ? "/upload" : "/create-channel"}>
              <IconTextWrapper>
                <VideoCallOutlinedIcon />
                <span>Upload</span>
              </IconTextWrapper>
            </ButtonStyled>
            <div style={{ position: "relative" }}>
              <IconButton onClick={toggleNotifications}>
                <Badge badgeContent={unreadCount} color="secondary">
                  <NotificationsIcon style={{ color: "#e5e5e5" }} />
                </Badge>
              </IconButton>
              <Notifications isOpen={isNotificationsOpen} onClose={toggleNotifications} />
            </div>
            <Link to="/profile">
              {currentUser.img ? (
                <Avatar src={currentUser.img} alt="User Avatar" />
              ) : (
                <DefaultAvatarIcon />
              )}
            </Link>
          </User>
        )}
      </Wrapper>
      <Menu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
    </Container>
  );
};

export default Navbar;
