import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { format } from "timeago.js";
import { FaEllipsisV, FaEdit, FaTrash } from "react-icons/fa";
import DeleteConfirmationModal from "./DeleteConfirmationModal"; // Import the delete modal component
import VideoEditModal from "./VideoEditModal"; // Import the edit modal component

const Container = styled.div`
  width: ${(props) => props.type !== "sm" && "350px"};
  margin-bottom: ${(props) => (props.type === "sm" ? "10px" : "45px")};
  cursor: pointer;
  display: ${(props) => props.type === "sm" && "flex"};
  gap: 1px;
  flex: 1 0 25%;
`;

const Image = styled.img`
  width: 100%;
  height: ${(props) => (props.type === "sm" ? "160px" : "220px")};
  background-color: #999;
  border-radius: 20px;
  flex: 1;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  color: white;
`;

const Title = styled.h1`
  font-size: 16px;
  font-weight: 500;
  color: white;
  margin: 0;
  flex: 2;
`;

const Separator = styled.div`
  width: 2px;
  background-color: #444;
  height: 100%;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  width: 100%;
`;

const Info = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Arial", sans-serif;
  color: white;
  font-weight: 400;
  text-align: center;
  flex: 1;
  &:first-child {
    flex: 3;
    text-align: left;
  }
`;

const Popup = styled.div`
  position: absolute;
  top: 40px;
  left: 20px;
  background-color: #333;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PopupButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;

  &:hover {
    color: #bbb;
  }
`;

const Card3 = ({ type, video, likes, comments, onVideoDeleted }) => {
  const [channel, setChannel] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const fetchChannel = async () => {
      if (video && video.channelId) {
        try {
          const res = await axios.get(`/channels/${video.channelId}`);
          setChannel(res.data);
        } catch (error) {
          console.error("Error fetching channel data:", error);
        }
      }
    };
    fetchChannel();
  }, [video]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  const handleDeleteSuccess = () => {
    setShowDeleteModal(false);
    onVideoDeleted(video._id); // Notify the parent component to remove the video
  };

  const handleEditSuccess = (updatedVideo) => {
    setShowEditModal(false);
    // Optionally, you can add logic to update the video details in the UI here
  };

  const openDeleteModal = () => {
    setShowPopup(false); // Close the initial popup
    setShowDeleteModal(true); // Show the delete confirmation modal
  };

  const openEditModal = () => {
    setShowPopup(false); // Close the initial popup
    setShowEditModal(true); // Show the edit modal
  };

  if (!video) {
    return null;
  }

  return (
    <Container type={type} ref={containerRef}>
      <Link to={`/video/${video._id}`} style={{ textDecoration: "none", display: 'flex', alignItems: 'center' }}>
        <Image type={type} src={video.imgUrl} />
      </Link>
      <Details type={type}>
        <InfoWrapper>
          <Title>{video.title}</Title>
          <Separator />
          <Info>{format(video.createdAt)}</Info>
          <Info>{video.views}</Info>
          <Info>{likes}</Info>
          <Info>{comments}</Info>
        </InfoWrapper>
      </Details>
      {showPopup && (
        <Popup>
          <PopupButton onClick={openEditModal}>
            <FaEdit />
            Edit
          </PopupButton>
          <PopupButton onClick={openDeleteModal}>
            <FaTrash />
            Delete
          </PopupButton>
        </Popup>
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          videoId={video._id}
          onDeleteSuccess={handleDeleteSuccess}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
      {showEditModal && (
        <VideoEditModal
          video={video}
          onEditSuccess={handleEditSuccess}
          onCancel={() => setShowEditModal(false)}
        />
      )}
    </Container>
  );
};

export default Card3;
