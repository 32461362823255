import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import ChannelImage from "../img/ChannelImage.jpeg";
import Card3 from "../components/Card3";
import ShortsCard from "../components/ShortsCard";
import Stats from "../components/Statistics";

const PageWrapper = styled.div`
  background-color: #333;
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #333;
  width: 100%;
  padding-top: 7vh;
  overflow-x: hidden;
`;

const ProfileContentContainer = styled.div`
  display: flex;
  padding: 20px;
  margin-left: 40px;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  width: 700px;
  background-color: #181818;
  border-radius: 5px;
`;

const DefaultAvatarIcon = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-right: 30px;
  background-image: url(${ChannelImage});
  background-size: cover;
`;

const UserDetails = styled.div`
  color: white;
  width: 70%;
`;

const ChannelName = styled.span`
  font-weight: bold;
  display: block;
  padding-bottom:2vh;
  font-size: 23px;
`;

const Description = styled.p`
  color: white;
  overflow: hidden;
  font-size:14px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({ expanded }) => (expanded ? "unset" : "1")};
  -webkit-box-orient: vertical;
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const TabButton = styled.button`
  background-color: transparent;
  border: none;
  margin-top: 4vh;
  margin-left: 50px;
  color: white;
  cursor: pointer;
  margin-right: 20px;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  border-bottom: ${({ active }) => (active ? "2px solid white" : "none")};
`;

const ContentContainer = styled.div`
  padding: 20px 40px;
  margin-top: 3px;
  background-color: #333;
`;

const HistoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ gap }) => gap || "15px"};
  margin-top: 20px;
  width: 100%;
`;

const ChannelPage = () => {
  const [channelData, setChannelData] = useState({});
  const [expandedDescription, setExpandedDescription] = useState(false);
  const [activeTab, setActiveTab] = useState("videos");
  const [videos, setVideos] = useState([]);
  const [shorts, setShorts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChannelDetails = async () => {
      setLoading(true);
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (!userData || !userData.ownedChannel) {
          throw new Error("Channel ID not found in local storage");
        }
        const channelId = userData.ownedChannel;
        const response = await axios.get(`/channels/details/${channelId}`);

        console.log("Channel Data Received:", response.data); // Log the data received from the server

        setChannelData(response.data);
      } catch (error) {
        console.error("Error fetching channel details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchChannelDetails();
  }, []);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const channelId = userData.ownedChannel;
        const response = await axios.get(`/videos/${channelId}`);
        setVideos(response.data);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    const fetchShorts = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const channelId = userData.ownedChannel;
        const response = await axios.get(`/shorts/${channelId}`);
        setShorts(response.data);
      } catch (error) {
        console.error("Error fetching shorts:", error);
      }
    };

    if (activeTab === "videos") {
      fetchVideos();
    } else {
      fetchShorts();
    }
  }, [activeTab]);

  return (
    <PageWrapper>
      <Container>
        <ProfileContentContainer>
          <ProfileContainer>
            <DefaultAvatarIcon />
            <UserDetails>
              <ChannelName>{channelData.name}</ChannelName>
              <Description expanded={expandedDescription}>{channelData.description}</Description>
            </UserDetails>
          </ProfileContainer>
          <Stats
            subscribers={channelData.subscribers?.length || 0}
            videos={videos?.length || 0}
            shorts={shorts?.length || 0}
            joined={new Date(channelData.createdAt).toLocaleDateString()}
            loading={loading}
          />
        </ProfileContentContainer>
        <TabContainer>
          <TabButton
            active={activeTab === "videos"}
            onClick={() => setActiveTab("videos")}
          >
            Videos
          </TabButton>
          <TabButton
            active={activeTab === "shorts"}
            onClick={() => setActiveTab("shorts")}
          >
            Shorts
          </TabButton>
        </TabContainer>
        <ContentContainer>
          {activeTab === "videos" && (
            <HistoryContainer gap="20px">
              {videos.map((video) => (
                <Card3 key={video._id} video={video} />
              ))}
            </HistoryContainer>
          )}
          {activeTab === "shorts" && (
            <HistoryContainer gap="10px">
              {shorts.map((short) => (
                <ShortsCard key={short._id} short={short} />
              ))}
            </HistoryContainer>
          )}
        </ContentContainer>
      </Container>
    </PageWrapper>
  );
};

export default ChannelPage;
