import React, { useState, useEffect } from 'react';
import { FaStar } from 'react-icons/fa';
import styled from 'styled-components';
import axios from 'axios';
import Modal from './Modal';

const StarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1vh;
  background-color: gray;
  padding: 10px;
  border-radius: 5px;
`;

const StarsContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const Star = styled(FaStar)`
  color: ${({ filled, hovered }) => (hovered ? '#CB4335' : filled ? 'blue' : '#ddd')};
  font-size: 24px;
  margin-right: 4px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  font-size: 16px;
  white-space: nowrap;
  position: relative;
`;

const HoverText = styled.div`
  color: black;
  border-radius: 3px;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  position: absolute;
`;

const RatingText = styled.div`
  color: black;
  visibility: ${({ visible }) => (visible ? 'hidden' : 'visible')};
`;

const StarRating = ({ videoId, initialRating, initialRatingCount }) => {
  const [hoverIndex, setHoverIndex] = useState(null);
  const [userRating, setUserRating] = useState(null);
  const [ratingText, setRatingText] = useState(`${initialRating} of 5 (${initialRatingCount} reviews)`);
  const [showModal, setShowModal] = useState(false);
  const [selectedRating, setSelectedRating] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [hasRated, setHasRated] = useState(false);

  const totalStars = 5;
  const hoverTexts = ["Not good", "Could be better", "OK", "Good", "Great"];

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      const userId = userData._id;
      // Fetch user rating status from backend
      axios.get(`/videos/${videoId}/user/${userId}/has-rated`)
        .then(response => {
          setHasRated(response.data.hasRated);
          if (response.data.hasRated) {
            setUserRating(response.data.rating);
            setRatingText(`You rated this video ${response.data.rating} stars`);
          }
        })
        .catch(error => {
          console.error('Error checking rating status:', error);
        });
    }
  }, [videoId]);

  const handleRatingClick = (index) => {
    if (!hasRated) {
      setSelectedRating(index + 1);
      setModalTitle(`You gave a ${index + 1} star rating`);
      setShowModal(true);
    } else {
      alert('You have already rated this video.');
    }
  };

  const handleModalSubmit = async ({ experience, recommend, improvements }) => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (!userData || !userData._id) {
        throw new Error("User ID not found in local storage");
      }
      const userId = userData._id;

      await axios.post(`/videos/${videoId}/rate`, {
        rating: selectedRating,
        experience,
        recommend,
        improvements,
        userId,
      }, { withCredentials: true });

      setUserRating(selectedRating);
      setRatingText(`You rated this video ${hoverTexts[selectedRating - 1]} (${selectedRating} stars)`);
      setHasRated(true);
    } catch (error) {
      console.error('Error rating video:', error);
      alert('Failed to rate video');
    }
  };

  return (
    <StarWrapper>
      <StarsContainer>
        {[...Array(totalStars)].map((_, index) => (
          <Star
            key={index}
            filled={index < userRating || index < Math.round((initialRating / 5) * totalStars)}
            hovered={index <= hoverIndex}
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(null)}
            onClick={() => handleRatingClick(index)}
          />
        ))}
      </StarsContainer>
      <TextContainer>
        <HoverText visible={hoverIndex !== null}>
          {hoverIndex !== null ? hoverTexts[hoverIndex] : ''}
        </HoverText>
        <RatingText visible={hoverIndex !== null}>
          {ratingText}
        </RatingText>
      </TextContainer>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleModalSubmit}
        title={modalTitle}
      />
    </StarWrapper>
  );
};

export default StarRating;
