import React, { useEffect, useState } from "react";
import styled from "styled-components";
import UserProfilePage from "../components/UserProfile";
import ChannelProfile from "../components/ChannelProfile";

// Styles for the profile page
const Container = styled.div`
  display: flex;
  background-color: #333;
  min-height: 100vh; /* Ensure the container covers the full viewport height */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
`;

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve user data from local storage
        const userData = localStorage.getItem("userData");
        if (!userData) {
          throw new Error("User data not found in local storage");
        }
        
        // Parse user data from string to object
        const userDataObj = JSON.parse(userData);
        setUserData(userDataObj);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Error fetching user data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      {!loading && !error && userData && (
        <>
          {userData.role === "channel" ? (
            <ChannelProfile userData={userData} />
          ) : (
            <>
              <UserProfilePage userData={userData} />
            </>
          )}
        </>
      )}
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
    </Container>
  );
};

export default Profile;
