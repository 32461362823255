import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Card from "../components/Card";
import SkeletonLoader from "../components/SkeletonLoader"; // Import SkeletonLoader

const HistoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #333;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 20px; /* Add padding to the container */

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.textSoft};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.bgLighter};
    border-radius: 10px;
  }

  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.textSoft} ${({ theme }) => theme.bgLighter};
`;

const CardsContainer = styled.div`
  margin-left: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 2vw;
`;

const Message = styled.p`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: white;
  font-weight: bold;
`;

const History = () => {
  const [watchedVideos, setWatchedVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (!userData || !userData._id) {
          throw new Error("User ID not found in local storage");
        }
        return userData._id;
      } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
      }
    };

    const fetchWatchedVideos = async () => {
      try {
        const userId = await fetchUserData();

        const endpoint = `/videos/${userId}/history`;
        const response = await axios.get(endpoint);

        console.log("Server Response:", response.data); // Log server response

        if (response.data.watchedVideos !== undefined) {
          setWatchedVideos(response.data.watchedVideos);
        } else if (response.data.message) {
          setErrorMessage(response.data.message);
        } else {
          throw new Error("Invalid data format received from server");
        }
      } catch (error) {
        console.error("Error fetching watched videos:", error);
        // Handle error display if needed
      } finally {
        setLoading(false);
      }
    };

    fetchWatchedVideos();
  }, []);

  if (loading) {
    return <SkeletonLoader />;
  }

  return (
    <HistoryContainer>
      {errorMessage ? (
        <Message>{errorMessage}</Message>
      ) : watchedVideos.length > 0 ? (
        <CardsContainer>
          {watchedVideos.map((video) => (
            <Card key={video._id} video={video} />
          ))}
        </CardsContainer>
      ) : (
        <Message>No watched videos found.</Message> // Display a message if no videos are found
      )}
    </HistoryContainer>
  );
};

export default History;
