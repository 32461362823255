import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { format } from "timeago.js";

// Define keyframes for loading animation
const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const fadeInOut = keyframes`
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
`;

const OptionsIcon = styled.div`
  cursor: pointer;
  font-size: 24px;
  color: white;
  position: absolute;
  right: 10px;
  top: 10px;
  display: ${(props) => (props.show ? "block" : "none")};
`;

const Container = styled.div`
  width: 62%;
  cursor: pointer;
  display: flex;
  margin-bottom: 2vh;
  gap: 10px;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  &:hover ${OptionsIcon} {
    display: block;
  }
`;

const Image = styled.img`
  width: 310px;
  height: 200px;
  background-color: #999;
  border-radius: 20px;
`;

const Details = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  margin-left: 16px;
  gap: 12px;
  flex: 1;
`;

const Texts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 19px;
  font-weight: 500;
  color: white;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 30px;
  left: 100%;
  background-color: #282828;
  border-radius: 5px;
  padding: 5px;
  display: ${(props) => (props.show ? "block" : "none")};
`;

const DropdownItem = styled.div`
  padding: 5px 10px;
  font-size: 14px;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #212121;
  }
`;

const ChannelName = styled.h2`
  font-size: 15px;
  color: ${({ theme }) => theme.textSoft};
  margin: 9px 0px;
  font-weight: 400;
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  margin-top: 0.7vw;
`;

const Info = styled.div`
  font-size: 14px;
  font-family: "Roboto", "Arial", sans-serif;
  color: ${({ theme }) => theme.textSoft};
  font-weight: 400;
`;

const Description = styled.p`
  font-size: 15px;
  color: ${({ theme }) => theme.textSoft};
  margin-top: 10px;
`;

const SkeletonContainer = styled.div`
  width: 300px;
  height: 190px;
  border-radius: 20px;
  background: #cccccc;
  background-image: linear-gradient(to right, #cccccc 0%, #aaaaaa 20%, #cccccc 40%, #cccccc 100%);
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  display: inline-block;
  position: relative;
  animation: ${shimmer} 1.5s infinite linear forwards;
`;

const SkeletonDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  gap: 12px;
`;

const SkeletonText = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "16px")};
  background: #cccccc;
  background-image: linear-gradient(to right, #cccccc 0%, #aaaaaa 20%, #cccccc 40%, #cccccc 100%);
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0")};
  animation: ${shimmer} 1.5s infinite linear forwards;
`;

const SkeletonInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  margin-top: 0.7vw;
`;

const Message = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: gray;
  color: white;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 16px;
  z-index: 1000;
  display: ${(props) => (props.show ? "block" : "none")};
  animation: ${fadeInOut} 3s ease-in-out;
`;

const Card4 = ({ video, displayProfileInfo, likes, comments, linkClicks, sales }) => {
  const [channel, setChannel] = useState({});
  const [loading, setLoading] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [iconVisible, setIconVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageVisible, setMessageVisible] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchChannel = async () => {
      try {
        const res = await axios.get(`/channels/${video.channelId}`);
        setChannel(res.data);
      } catch (error) {
        console.error("Error fetching channel data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (video.channelId) {
      fetchChannel();
    } else {
      console.error("video.channelId is undefined");
      setLoading(false);
    }
  }, [video.channelId]);

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setDropdownVisible((prev) => !prev);
    setIconVisible((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest('.options-icon')
    ) {
      setDropdownVisible(false);
      setIconVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSaveVideo = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (!userData || !userData._id) {
        throw new Error("User ID not found in local storage");
      }
      const userId = userData._id;

      await axios.post(`/api/videos/${userId}/save`, { videoId: video._id });
    } catch (error) {
      console.error('Error saving video:', error);
    }
    setDropdownVisible(false);
  };

  const handleShareVideo = () => {
    const videoUrl = `${window.location.origin}/video/${video._id}`;
    navigator.clipboard.writeText(videoUrl)
      .then(() => {
        setMessage("Video link copied to clipboard");
        setMessageVisible(true);
        setTimeout(() => setMessageVisible(false), 1500);
      })
      .catch(err => {
        console.error('Failed to copy video link: ', err);
      });
    setDropdownVisible(false);
  };

  return (
    <>
      <Message show={messageVisible}>{message}</Message>
      <Container
        onMouseEnter={() => setIconVisible(true)}
        onMouseLeave={() => !dropdownVisible && setIconVisible(false)}
      >
        <Link to={`/video/${video._id}`} style={{ textDecoration: "none" }}>
          {loading ? (
            <SkeletonContainer />
          ) : (
            <Image src={video.imgUrl} />
          )}
        </Link>
        {loading ? (
          <SkeletonDetails>
            <Texts>
              <SkeletonText width="80%" height="20px" />
              <SkeletonText width="60%" marginTop="10px" />
              {displayProfileInfo ? (
                <SkeletonInfoWrapper>
                  <SkeletonText width="50%" />
                  <SkeletonText width="50%" />
                  <SkeletonText width="50%" />
                  <SkeletonText width="50%" />
                  <SkeletonText width="50%" />
                  <SkeletonText width="50%" />
                </SkeletonInfoWrapper>
              ) : (
                <>
                  <SkeletonText width="40%" marginTop="10px" />
                </>
              )}
            </Texts>
          </SkeletonDetails>
        ) : channel._id ? (
          <Details>
            <Texts>
              <TitleWrapper>
                <Link to={`/video/${video._id}`} style={{ textDecoration: "none", color: "white" }}>
                  <Title>{video.title}</Title>
                </Link>
                <OptionsIcon
                  className="options-icon"
                  show={iconVisible || dropdownVisible}
                  onClick={toggleDropdown}
                >
                  ⋮
                  <DropdownMenu ref={dropdownRef} show={dropdownVisible}>
                    <DropdownItem onClick={handleSaveVideo}>Save</DropdownItem>
                    <DropdownItem onClick={handleShareVideo}>Share</DropdownItem>
                  </DropdownMenu>
                </OptionsIcon>
              </TitleWrapper>
              {displayProfileInfo ? (
                <>
                  <InfoWrapper>
                    <Info>{format(video.createdAt)}</Info>
                    <Info>{video.views} views</Info>
                    <Info>{likes} likes</Info>
                    <Info>{comments} comments</Info>
                    <Info>{linkClicks} link clicks</Info>
                    <Info>{sales} sales</Info>
                  </InfoWrapper>
                  <ChannelName>{channel?.name}</ChannelName>
                  <Description>{video.desc}</Description>
                </>
              ) : (
                <>
                  <Info>
                    {video.views} views • {format(video.createdAt)}
                  </Info>
                  <ChannelName>{channel?.name}</ChannelName>
                  <Description>{video.desc}</Description>
                </>
              )}
            </Texts>
          </Details>
        ) : (
          <p>Error loading channel data.</p>
        )}
      </Container>
    </>
  );
};

export default Card4;