import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Comment from "./Comment";

const Container = styled.div``;

const NewComment = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const Input = styled.input`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  background-color: transparent;
  outline: none;
  padding: 5px;
  width: 100%;
`;

const Button = styled.button`
  padding: 5px 10px;
  border: none;
  background-color: ${({ primary }) => (primary ? "#3498db" : "#e74c3c")};
  color: white;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 4vh;
  border-radius: 3px;
  &:hover {
    opacity: 0.9;
  }
`;

const CommentActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
`;

const Comments = ({ videoId }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [isAddingComment, setIsAddingComment] = useState(false);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const res = await axios.get(`/comments/${videoId}`);
        setComments(res.data);
      } catch (err) {}
    };
    fetchComments();
  }, [videoId]);

  const handleAddComment = async () => {
    if (!newComment.trim()) {
      return;
    }

    try {
      const res = await axios.post(
        "/comments",
        {
          videoId,
          userId: currentUser._id,
          text: newComment,
        },
        { withCredentials: true }
      );
      setComments([...comments, res.data]);
      setNewComment("");
      setIsAddingComment(false);
    } catch (err) {
      console.error("Error adding comment:", err);
    }
  };

  const handleCancel = () => {
    setNewComment("");
    setIsAddingComment(false);
  };

  return (
    <Container>
      <NewComment>
        <Avatar src={currentUser.img} />
        <Input
          placeholder="Add a comment..."
          value={newComment}
          onChange={(e) => {
            setNewComment(e.target.value);
            if (!isAddingComment) setIsAddingComment(true);
          }}
        />
      </NewComment>
      {isAddingComment && (
        <CommentActions>
          <Button primary onClick={handleAddComment}>
            Comment
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </CommentActions>
      )}
      {comments.map((comment) => (
        <Comment key={comment._id} comment={comment} />
      ))}
    </Container>
  );
};

export default Comments;
