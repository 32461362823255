import React from 'react';
import styled from 'styled-components';

const SkeletonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #333;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding-top: 10vh;
`;

const SkeletonCard = styled.div`
  background-color: #444;
  border-radius: 8px;
  width: 390px;
  height: 230px;
  margin: 10px;
  animation: pulse 1.5s infinite ease-in-out;
  
  @keyframes pulse {
    0% {
      background-color: #444;
    }
    50% {
      background-color: #555;
    }
    100% {
      background-color: #444;
    }
  }
`;

const SkeletonLoader = () => {
  return (
    <SkeletonContainer>
      {Array.from({ length: 3 }).map((_, index) => (
        <SkeletonCard key={index} />
      ))}
    </SkeletonContainer>
  );
};

export default SkeletonLoader;
