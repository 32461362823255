import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Card from "../components/Card";
import SkeletonLoader from "../components/SkeletonLoader"; // Import SkeletonLoader
import notifImage from "../img/notifImage.png"; // Ensure the path is correct based on your project structure

const LikedVideosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #333;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 20px; /* Add padding to the container */

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.textSoft};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.bgLighter};
    border-radius: 10px;
  }

  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.textSoft} ${({ theme }) => theme.bgLighter};
`;

const CardsContainer = styled.div`
  margin-left: 30px;
  display: flex;
  margin-top: 2vw;
  flex-wrap: wrap;
  gap: 20px;
`;

const Message = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: white;
  font-weight: bold;
`;

const NotifImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
`;

const LikedVideos = () => {
  const [likedVideos, setLikedVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchLikedVideos = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (!userData || !userData._id) {
          throw new Error("User ID not found in local storage");
        }
        const userId = userData._id;
        const endpoint = `/videos/${userId}/liked`;

        const response = await axios.get(endpoint);

        if (Array.isArray(response.data)) {
          setLikedVideos(response.data);
        } else if (response && response.data && response.data.message) {
          setErrorMessage(response.data.message);
        } else {
          console.error("Data from server is not in the expected format:", response.data);
          setErrorMessage("Unexpected data format received from server.");
        }
      } catch (error) {
        console.error("Error fetching liked videos:", error);
        if (error.response) {
          console.log("Server response:", error.response.data);
          setErrorMessage(error.response.data.message || "Error fetching liked videos. Please try again later.");
        } else {
          setErrorMessage("Error fetching liked videos. Please try again later.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchLikedVideos();
  }, []);

  if (loading) {
    return <SkeletonLoader />;
  }

  return (
    <LikedVideosContainer>
      {errorMessage && <Message>{errorMessage}</Message>}
      {(likedVideos.length === 0 && !errorMessage) && (
        <NotifImage src={notifImage} alt="No Videos" />
      )}
      {likedVideos.length > 0 && (
        <CardsContainer>
          {likedVideos.map((video) => (
            <Card key={video._id} video={video} />
          ))}
        </CardsContainer>
      )}
    </LikedVideosContainer>
  );
};

export default LikedVideos;
