import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import HomeIcon from "@mui/icons-material/Home";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import SettingsBrightnessOutlinedIcon from "@mui/icons-material/SettingsBrightnessOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import LamaTubeLogo from "../img/logo.png";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined"; // Thumbs-up icon
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined"; // History icon
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined"; // Saved Videos icon
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/userSlice";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  width: 17%;
  background-color:#282828;
  font-size: 14px;
  height:100vh;
  top:0;
  overflow-y: auto;
  position: fixed;
  overflow-x: hidden;
  z-index: 2000;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 4px;
  }
  /* Slide-in animation */
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(-100%)")};
  transition: transform 0.3s ease-in-out;
`;

const Overlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")}; /* Display only when menu is open */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 0; /* Always below the menu */
`;

const Wrapper = styled.div`
  padding: 3px 17px;
  box-sizing: border-box;
  height: 100%;
`;
const MenuIconContainer = styled.div`
display: flex;
align-items: center;
gap: 30px; /* Adjust the gap between the menu icon and the logo container */
`;

const Item = styled.div`
display: flex;
align-items: center;
font-size: 16px;
color: white;
font-family: Roboto, Arial, sans-serif;
cursor: pointer;
padding: 14px 6px;
gap: 20px;
box-sizing: border-box;
border-radius: 11%;
&:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
`;

const Separator = styled.div`
  width: 80%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 8px auto;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust the gap as needed */
  top:0;
`;

const LogoImage = styled.img`
  width: 40px;
  height: 30px;
`;

const LogoName = styled.span`
  color: white;
  font-weight: bold;
`;

const Menu = ({ darkMode, setDarkMode, isOpen, toggleMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target) && isOpen) {
        toggleMenu(); // Close the menu if clicked outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef, isOpen, toggleMenu]);

  const handleLogout = async () => {
    dispatch(logout());

    try {
      await fetch("/auth/logout", {
        method: "POST",
        credentials: "include",
      });

      // Close the menu after successful logout
      toggleMenu();
      // Navigate to the home page or any other desired destination
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <>
      <Overlay isOpen={isOpen} onClick={toggleMenu} />
      <Container isOpen={isOpen} ref={menuRef}>
        <Wrapper>
          <Item onClick={toggleMenu} style={{ marginBottom: "20px", cursor: "pointer", backgroundColor: "transparent" }}>
            <MenuIconContainer>
              <MenuIcon />
              <LogoContainer>
                <LogoImage src={LamaTubeLogo} alt="LamaTube Logo" />
                <LogoName>FRIXOS</LogoName>
              </LogoContainer>
            </MenuIconContainer>
          </Item>
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            <Item>
              <HomeIcon />
              Home
            </Item>
          </Link>
          <Link to="/trends" style={{ textDecoration: "none", color: "inherit" }}>
            <Item>
              <ExploreOutlinedIcon />
              Top Rated
            </Item>
          </Link>
          <Separator /> {/* Separator between Trending and Subscriptions */}
          <Link to="/subscriptions" style={{ textDecoration: "none", color: "inherit" }}>
            <Item>
              <SubscriptionsOutlinedIcon />
              Subscriptions
            </Item>
          </Link>
          <Link to="/likedvideos" style={{ textDecoration: "none", color: "inherit" }}> {/* Add link to Liked Videos */}
            <Item>
              <ThumbUpOutlinedIcon /> {/* Replaced with thumbs-up icon */}
              Liked
            </Item>
          </Link>
          <Link to="/savedvideos" style={{ textDecoration: "none", color: "inherit" }}> {/* Add link to Saved Videos */}
            <Item>
              <BookmarkOutlinedIcon /> {/* Icon for Saved Videos */}
              Saved
            </Item>
          </Link>
          <Link to="/history" style={{ textDecoration: "none", color: "inherit" }}> {/* Add link to History */}
            <Item>
              <HistoryOutlinedIcon />
              History
            </Item>
          </Link>
          <Separator />
          <Item>
            <SettingsOutlinedIcon />
            Settings
          </Item>
          <Item onClick={() => setDarkMode(!darkMode)}>
            <SettingsBrightnessOutlinedIcon />
            {darkMode ? "Light" : "Dark"} Mode
          </Item>
          <Item onClick={handleLogout}>
            <ExitToAppOutlinedIcon />
            Logout
          </Item>
        </Wrapper>
      </Container>
    </>
  );
};

export default Menu;