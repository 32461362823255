import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import axios from "axios";
import { loginFailure, loginStart, loginSuccess } from "../redux/userSlice";
import { useNavigate } from "react-router-dom";
import { signInWithPopup } from "firebase/auth";
import { auth, provider } from "../firebase"; // Import auth and provider from firebase.jsx
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px 30px;
  gap: 10px;
  max-width: 400px;
  width: 100%;
`;

const Title = styled.h1`
  font-size: 26px;
`;

const SubTitle = styled.h2`
  font-size: 20px;
  font-weight: 300;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.soft};
  border-radius: 3px;
  padding: 18px;
  background-color: transparent;
  width: calc(100% - 30px);
  color: black;
  font-size: 17px;
`;

const EyeIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;

const Button = styled.button`
  border-radius: 3px;
  border: none;
  padding: 15px;
  font-weight: 500;
  cursor: pointer;
  background-color: #4f0341;
  color: white;
  width: 100%;
  transition: background-color 0.3s;

  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  }
`;

const More = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 12px;
  color: ${({ theme }) => theme.textSoft};
`;

const Link = styled.span`
  margin: 0 15px;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
`;

const SignInPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      setError("Username and password are required.");
      return;
    }

    dispatch(loginStart());
    try {
      const res = await axios.post("/auth/signin", { username, password });
      const { _id, token, role, ownedChannel } = res.data;
      console.log("Response Data:", res.data);

      document.cookie = `token=${token}; Secure; HttpOnly; SameSite=Lax; `;
      const userData = {
        _id,
        role,
        ownedChannel,
        username
      };
      localStorage.setItem("userData", JSON.stringify(userData));

      dispatch(loginSuccess(res.data));
      navigate("/");
    } catch (err) {
      console.error("Sign-in error:", err);
      setError(err.response.data.message);
      dispatch(loginFailure());
    }
  };

  const signInWithGoogle = async () => {
    dispatch(loginStart());
    try {
      const result = await signInWithPopup(auth, provider);
      const res = await axios.post("/auth/google", {
        username: result.user.displayName,
        email: result.user.email,
        img: result.user.photoURL,
      });

      const { _id: extractedUserId, token } = res.data;

      localStorage.setItem("userId", extractedUserId);
      localStorage.setItem("userToken", token);

      dispatch(loginSuccess(res.data));
      navigate("/");
    } catch (error) {
      console.error("Google sign-in error:", error);
      dispatch(loginFailure());
    }
  };

  return (
    <Container>
      <Wrapper>
        <Title>Welcome Back</Title>
        <SubTitle>Enter your credentials</SubTitle>
        <Input placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
        <InputWrapper>
          <Input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <EyeIcon onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </EyeIcon>
        </InputWrapper>
        <Button onClick={handleLogin}>Sign in</Button>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <Title>or</Title>
        <Button onClick={signInWithGoogle}>Sign in with Google</Button>
      </Wrapper>
      <More>
        <Link onClick={() => navigate("/forgotpassword")}>Forgot password?</Link>
        <Link onClick={() => navigate("/signup")}>Don't have an account? Sign up</Link>
      </More>
    </Container>
  );
};

export default SignInPage;
