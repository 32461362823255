import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import SaveConfirmationModal from "./SaveConfirmationModal";

// Axios configuration to include credentials
axios.defaults.withCredentials = true;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
`;

const Modal = styled.div`
  background-color: #333;
  padding: 3vh;
  border-radius: 1vh;
  box-shadow: 0 0 2vh rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  width: 80vw;
  max-width: 80vw;
  position: relative;
  overflow-y: hidden; /* Enable scrolling within modal content if necessary */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1vh;
  right: 1vh;
  background: none;
  border: none;
  color: white;
  font-size: 2vh;
  cursor: pointer;
`;

const Input = styled.input`
  padding: 1vh;
  border-radius: 0.5vh;
  border: none;
  width: 90%;
  margin-bottom: 1vh;
`;

const Textarea = styled.textarea`
  padding: 1vh;
  border-radius: 0.5vh;
  border: none;
  width: 90%;
  margin-bottom: 1vh;
  resize: none;
`;

const Image = styled.img`
  width: 90%;
  height: 20vh;
  object-fit: cover;
  background-color: #999;
  border-radius: 0.5vh;
  margin-bottom: 1vh;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1vh;
`;

const ModalButton = styled.button`
  background-color: #444;
  border: none;
  color: white;
  padding: 1vh 2vh;
  cursor: pointer;
  border-radius: 0.5vh;

  &:hover {
    background-color: #555;
  }
`;

const Header = styled.h3`
  margin-bottom: 0.5vh;
  color: white;
`;

const SuccessMessage = styled.div`
  color: white;
  text-align: center;
`;

const SavedButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 1vh 2vh;
  border: none;
  border-radius: 0.5vh;
  cursor: not-allowed;
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
`;

const VideoEditModal = ({ video, onCancel, onEditSuccess }) => {
  const [title, setTitle] = useState(video.title);
  const [tags, setTags] = useState(video.tags.join(", "));
  const [isEditing, setIsEditing] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);

  useEffect(() => {
    // Disable scrolling when modal is open
    document.body.style.overflow = "hidden";
    return () => {
      // Re-enable scrolling when modal is closed
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleEdit = async () => {
    try {
      const response = await axios.put(
        `/videos/${video._id}`,
        { title, tags: tags.split(",").map((tag) => tag.trim()) },
        { withCredentials: true }
      );
      onEditSuccess({ ...video, title, tags: tags.split(",").map((tag) => tag.trim()) });
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating video:", error);
      setErrorMessage(error.response?.data?.message || "Errors saving changes. Try again later.");
      setIsEditing(false);
    }
  };

  const confirmSave = () => {
    setShowSaveConfirmation(true);
  };

  const handleConfirmSave = () => {
    setShowSaveConfirmation(false);
    handleEdit();
  };

  return (
    <>
      <ModalOverlay>
        <Modal>
          <CloseButton onClick={onCancel}>&times;</CloseButton>
          <h2>Edit Video</h2>
          <Image src={video.imgUrl} alt="Video Thumbnail" />
          <Header>Title</Header>
          <Input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title"
            disabled={!isEditing}
          />
          <Header>Tags</Header>
          <Textarea
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            placeholder="Tags (comma separated)"
            disabled={!isEditing}
          />
          {isEditing ? (
            <ModalButtons>
              <ModalButton onClick={onCancel}>Cancel</ModalButton>
              <ModalButton onClick={confirmSave}>Save</ModalButton>
            </ModalButtons>
          ) : errorMessage ? (
            <ErrorMessage>{errorMessage}</ErrorMessage>
          ) : (
            <SavedButton>Saved</SavedButton>
          )}
        </Modal>
      </ModalOverlay>

      {showSaveConfirmation && (
        <SaveConfirmationModal
          onCancel={() => setShowSaveConfirmation(false)}
          onConfirmSave={handleConfirmSave}
        />
      )}
    </>
  );
};

export default VideoEditModal;
