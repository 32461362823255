import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Card2 from "./Card2";
import myCardImage3 from "../img/myCardImage3.jpg";

// Styles for the profile section
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333;
  margin-top: 50px;
  min-height: 100vh;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal scrolling */

  @media (max-width: 768px) {
    margin-top:60px;
  }
`;

const DefaultAvatarIcon = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-bottom: 20px;
  background-image: url(${myCardImage3});
  background-size: cover;
`;

const UserDetails = styled.div`
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const Name = styled.span`
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  font-size: 23px;
`;

const Username = styled.span`
  color: #666;
  display: block;
  margin-bottom: 5px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 13px;
  gap: 8vw;
`;

const Button = styled.button`
  background-color: #555555;
  color: white;
  border: none;
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #777777;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
`;

const TabButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-bottom: ${props => (props.active ? '1px solid #ffffff' : 'none')};
  flex: 1;
  text-align: center;
  padding: 0 10px; /* Adjust padding to reduce the border width */
  width: auto; /* Ensure the button does not stretch */
  display: inline-block; /* Inline-block to shrink the width */
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1200px; /* Adjust the max-width as needed */
  box-sizing: border-box;
  padding: 0 20px; /* Add some padding to avoid touching the edges */
`;

const VideoContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Change to wrap to avoid overflow */
  justify-content: flex-start; /* Center the items */
  gap: 20px;
  margin-top: 20px;
`;

const ErrorMessage = styled.p`
  color: white;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
`;

const UserProfilePage = () => {
  const [user, setUser] = useState(null);
  const [videos, setVideos] = useState({ likedVideos: [], watchedVideos: [], savedVideos: [], subscribedChannelVideos: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState("watched");

  const navigate = useNavigate(); // Use this for navigation

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (!userData || !userData._id) {
          throw new Error("User ID not found in local storage");
        }
        const userId = userData._id;

        const userEndpoint = `/users/${userId}/profile`;
        const userResponse = await axios.get(userEndpoint);
        const { email, name, username, role } = userResponse.data;
        setUser({ email, name, username, role });

        const contentEndpoint = `/videos/${userId}/content`;
        const contentResponse = await axios.get(contentEndpoint);
        setVideos(contentResponse.data);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setError("Error fetching user profile. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const handleChannelButtonClick = () => {
    if (user.role === "channel") {
      navigate("/channel"); // Navigate to the channel page
    } else {
      // Handle create channel logic here
      console.log("Redirect to create channel page");
    }
  };

  return (
    <Container>
      {user && (
        <>
          <DefaultAvatarIcon />
          <UserDetails>
            <Name>{user.name}</Name>
            <Username>@{user.username}</Username>
          </UserDetails>
          <ButtonsContainer>
            <Button onClick={handleChannelButtonClick}>
              {user.role === "channel" ? "Visit  My Channel" : "Create Channel"}
            </Button>
            <Button>Edit Profile</Button>
          </ButtonsContainer>
          <TabsContainer>
            <TabButton active={activeTab === "watched"} onClick={() => setActiveTab("watched")}>
              Watched
            </TabButton>
            <TabButton active={activeTab === "saved"} onClick={() => setActiveTab("saved")}>
              Saved
            </TabButton>
            <TabButton active={activeTab === "subscriptions"} onClick={() => setActiveTab("subscriptions")}>
              Subscriptions
            </TabButton>
            <TabButton active={activeTab === "liked"} onClick={() => setActiveTab("liked")}>
              Liked
            </TabButton>
          </TabsContainer>
          <ContentContainer>
            {loading && <p>Loading...</p>}
            {!loading && error && <ErrorMessage>{error}</ErrorMessage>}
            {!loading && !error && (
              <>
                {activeTab === "watched" && (
                  <>
                    {Array.isArray(videos.watchedVideos) ? (
                      videos.watchedVideos.length > 0 ? (
                        <VideoContainer>
                          {videos.watchedVideos.map((video) => (
                            <Card2 key={video._id} video={video} />
                          ))}
                        </VideoContainer>
                      ) : (
                        <ErrorMessage>{videos.watchedVideos.message}</ErrorMessage>
                      )
                    ) : (
                      <ErrorMessage>{videos.watchedVideos.message}</ErrorMessage>
                    )}
                  </>
                )}
                {activeTab === "saved" && (
                  <>
                    {Array.isArray(videos.savedVideos) ? (
                      videos.savedVideos.length > 0 ? (
                        <VideoContainer>
                          {videos.savedVideos.map((video) => (
                            <Card2 key={video._id} video={video} />
                          ))}
                        </VideoContainer>
                      ) : (
                        <ErrorMessage>{videos.savedVideos.message}</ErrorMessage>
                      )
                    ) : (
                      <ErrorMessage>{videos.savedVideos.message}</ErrorMessage>
                    )}
                  </>
                )}
                {activeTab === "subscriptions" && (
                  <>
                    {Array.isArray(videos.subscribedChannelVideos) ? (
                      videos.subscribedChannelVideos.length > 0 ? (
                        <VideoContainer>
                          {videos.subscribedChannelVideos.map((video) => (
                            <Card2 key={video._id} video={video} />
                          ))}
                        </VideoContainer>
                      ) : (
                        <ErrorMessage>{videos.subscribedChannelVideos.message}</ErrorMessage>
                      )
                    ) : (
                      <ErrorMessage>{videos.subscribedChannelVideos.message}</ErrorMessage>
                    )}
                  </>
                )}
                {activeTab === "liked" && (
                  <>
                    {Array.isArray(videos.likedVideos) ? (
                      videos.likedVideos.length > 0 ? (
                        <VideoContainer>
                          {videos.likedVideos.map((video) => (
                            <Card2 key={video._id} video={video} />
                          ))}
                        </VideoContainer>
                      ) : (
                        <ErrorMessage>{videos.likedVideos.message}</ErrorMessage>
                      )
                    ) : (
                      <ErrorMessage>{videos.likedVideos.message}</ErrorMessage>
                    )}
                  </>
                )}
              </>
            )}
          </ContentContainer>
        </>
      )}
    </Container>
  );
};

export default UserProfilePage;
