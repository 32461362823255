import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "./utils/Theme";
import Navbar from "./components/Navbar";
import SignUp from "./pages/SignUp";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import SubscriptionPage from "./pages/Subscriptions";
import Search from "./pages/Search";
import Profile from "./pages/Profile";
import Video from "./pages/Video";
import Menu from "./components/Menu";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import MyVideos from "./pages/MyVideos";
import LikedVideos from "./pages/LikedVideos";
import History from "./pages/History";
import SavedVideos from "./pages/SavedVideos";
import DummyVideoPage from "./pages/DummyVideoPage";
import ChannelProfile from "./pages/ChannelProfile";
import UserProfilePage from "./components/UserProfile";
import Notifications from "./components/Notifications";
import Upload from "./pages/UploadPage";
import BottomNav from "./components/BottomNav"; // Import BottomNav component
import ChannelPage from "./pages/ChannelPage";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  display: flex;
`;

const Main = styled.main`
  flex: 1;
`;

const ProgressContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: ${props => props.theme.primaryColor};
  z-index: 9999;
  transition: width 0.2s ease;
`;

function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { currentUser } = useSelector((state) => state.user);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  // Simulate loading for demonstration
  const simulateLoading = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Router>
        <AppContainer>
          <ProgressContainer style={{ width: isLoading ? "100%" : "0" }} />
          <Navbar toggleSidebar={toggleSidebar} />
          <Menu
            darkMode={darkMode}
            setDarkMode={setDarkMode}
            isOpen={isSidebarOpen}
            toggleMenu={toggleSidebar}
          />
          <MainContainer>
            <Main>
              <Routes>
                <Route path="/" element={<Home type="random" isSidebarOpen={isSidebarOpen} />} />
                <Route path="/trends" element={<Home type="trend" isSidebarOpen={isSidebarOpen} />} />
                <Route path="/subscriptions" element={<SubscriptionPage />} />
                <Route path="/search" element={<Search />} />
                <Route path="/signin" element={currentUser ? <Home /> : <SignIn />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/video/:id" element={<Video />} />
                <Route path="/myvideos" element={<MyVideos />} />
                <Route path="/likedvideos" element={<LikedVideos />} />
                <Route path="/history" element={<History />} />
                <Route path="/savedvideos" element={<SavedVideos />} />
                <Route path="/dummyvideo" element={<DummyVideoPage />} />
                <Route path="/channel" element={<ChannelProfile />} />
                <Route path="/channel/:id" element={<ChannelPage />} />
                <Route path="/userprofile" element={<UserProfilePage />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/upload" element={<Upload />} />
              </Routes>
            </Main>
          </MainContainer>
          <BottomNav /> {/* Add BottomNav here */}
        </AppContainer>
      </Router>
    </ThemeProvider>
  );
}

export default App;
