import React, { useState } from "react";
import styled from "styled-components";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useNavigate } from "react-router-dom";

const SearchContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;

  @media (max-width: 768px) {
    width: 80%;
    transform: translateX(-56%);
    margin-right:0px;
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 6px;
  background-color: #FFFFFF;
  align-items: center;
  justify-content: space-between;
  padding: 0.5vw;
  border: 1px solid #ccc;
  border-right: 0px;
  color: ${({ theme }) => theme.text};
  height: 3vh;
  transition: box-shadow 0.3s; /* Add transition for smooth effect */

  &:focus-within {
    box-shadow: 0 0 0.4vw 0.2vw #3ea6ff; /* Add light blue glow when focused */
  }

  @media (max-width: 768px) {
    height: 3.5vh;
  }
`;

const Input = styled.input`
  border: none;
  background-color: transparent;
  outline: none;
  flex: 1;
  font-size: 1vw;
  height: 3vh;
  caret-color: black;

  @media (max-width: 768px) {
    font-size: 3vw;
  }
`;

const SearchIcon = styled(SearchOutlinedIcon)`
  color: white;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;
  background-color: #CB4335;
  padding: 0.5vw; /* Updated padding to remove right padding */
  border-radius: 0.5vw;
  border-left: 0px;
  margin-right: -0.5vw; /* Updated margin to remove right margin */

  &:hover {
    color: #555;
    background-color: #CCCCCC;
  }

  @media (max-width: 768px) {
    padding:1.7vw;
  }
`;

const SearchBar = () => {
  const navigate = useNavigate();
  const [q, setQ] = useState("");

  const handleSearch = () => {
    navigate(`/search?q=${q}`);
  };

  return (
    <SearchContainer>
      <SearchWrapper>
        <Input
          placeholder="Search"
          value={q}
          onChange={(e) => setQ(e.target.value)}
        />
        <SearchIcon onClick={handleSearch} />
      </SearchWrapper>
    </SearchContainer>
  );
};

export default SearchBar;
