import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Typography } from '@mui/material';
import theme from '../theme';

const StatisticsContainer = styled.div`
  background-color: #181818;
  height: 15.1vw;
  width: 22vw;
  border-radius: 0.5vw;
  margin-left: 3vw;
  box-shadow: 0 4px 6px 1px rgba(0, 0, 0, 0.1);

  @media (max-width: 480px) {
    display: none;
  }
`;

const ElevatedStats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1vw;
  margin-left: 1vw;
  margin-right: 2.5vw;
  padding-right: 1vw;
  padding-top: 0.8vw;
  padding-bottom: 0.6vw;
  padding-left: 1vw;
  background-color: ${({ bgColor }) => `rgba(240, 240, 240, 0.03)`};
  box-shadow: 0 4px 6px 1px rgba(0, 0, 0, 0.1);
  font-size: ${({ theme }) => theme.typography.subtitle};
  color: #dddddd;
  text-align: left;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.2);
  }
`;

const SkeletonBox = styled.div`
  background: #444;
  border-radius: 5px;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '20px'};
  margin: 10px 0;
`;

const Stats = ({ subscribers, videos, shorts, joined, loading }) => {
  return (
    <ThemeProvider theme={theme}>
      <StatisticsContainer>
        {loading ? (
          <>
            <ElevatedStats>
              <SkeletonBox width="80%" height="20px" />
            </ElevatedStats>
            <ElevatedStats>
              
            </ElevatedStats>
            <ElevatedStats>
            </ElevatedStats>
            <ElevatedStats>
            </ElevatedStats>
          </>
        ) : (
          <>
            <ElevatedStats>
              <Typography variant="h7" style={{ color: '#dddddd', textAlign: 'left' }}>
                Subscribers :
              </Typography>
              <Typography variant="h7" style={{ color: '#dddddd', textAlign: 'right' }}>
                {subscribers}
              </Typography>
            </ElevatedStats>
            <ElevatedStats>
              <Typography variant="h7" style={{ color: '#dddddd', textAlign: 'left' }}>
                Videos :
              </Typography>
              <Typography variant="h7" style={{ color: '#dddddd', textAlign: 'right' }}>
                {videos}
              </Typography>
            </ElevatedStats>
            <ElevatedStats>
              <Typography variant="h7" style={{ color: '#dddddd', textAlign: 'left' }}>
                Shorts :
              </Typography>
              <Typography variant="h7" style={{ color: '#dddddd', textAlign: 'right' }}>
                {shorts}
              </Typography>
            </ElevatedStats>
            <ElevatedStats>
              <Typography variant="h7" style={{ color: '#dddddd', textAlign: 'left' }}>
                Joined:
              </Typography>
              <Typography variant="h7" style={{ color: '#dddddd', textAlign: 'right' }}>
                {joined}
              </Typography>
            </ElevatedStats>
          </>
        )}
      </StatisticsContainer>
    </ThemeProvider>
  );
};

export default Stats;
