import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import app from "../firebase";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UploadPreview from "../components/UploadPreview"; // Import the new component

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 90%;
  height: auto;
  background-color: ${({ theme }) => theme.bgLighter};
  color: ${({ theme }) => theme.text};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 4%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  width:80%;
  margin-left:80px;
  font-size: 15px;
  padding: 20px 10px;
  background-color: transparent;
  margin-top: 5px; /* Adjust margin as needed */
  &:focus {
    margin-top: 10px; /* Adjust focus margin as needed */
  }
`;

const Desc = styled.textarea`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  margin-left:80px;
  font-size: 15px;
  width:80%;
  padding: 20px 10px;
  background-color: transparent;
  margin-top: 5px; /* Adjust margin as needed */
  &:focus {
    margin-top: 10px; /* Adjust focus margin as needed */
  }
`;

const Label = styled.label`
  position: absolute;
  top: 10px;
  left: 10px;
  margin-left:80px;
  font-size: 14px;
  color: #777;
  pointer-events: none;
  transition: 0.2s;
  ${Input}:focus ~ &,
  ${Input}:not(:placeholder-shown) ~ &,
  ${Desc}:focus ~ &,
  ${Desc}:not(:placeholder-shown) ~ & {
    top: -10px;
    left: 10px;
    font-size: 12px;
    color: ${({ theme }) => theme.text};
  }
`;

const Button = styled.button`
  border-radius: 3px;
  border: none;
  padding: 10px 20px;
  font-weight: 500;
  cursor: pointer;
  background-color: #f7f7f7;
  color: #282828;
  align-self: flex-end;
`;

const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const VideoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const Upload = ({ setOpen }) => {
  const [img, setImg] = useState(undefined);
  const [autoGeneratedImg, setAutoGeneratedImg] = useState(undefined);
  const [video, setVideo] = useState(undefined);
  const [imgPerc, setImgPerc] = useState(0);
  const [autoImgPerc, setAutoImgPerc] = useState(0);
  const [videoPerc, setVideoPerc] = useState(0);
  const [inputs, setInputs] = useState({});
  const [tags, setTags] = useState([]);
  const [affiliateLink, setAffiliateLink] = useState(""); // State for affiliate link
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    if (e.target.name === "tags") {
      setTags(e.target.value.split(","));
    } else {
      setInputs((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleTags = (e) => {
    setTags(e.target.value.split(","));
  };

  const uploadFile = (file, urlType) => {
    const storage = getStorage(app);
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        urlType === "imgUrl"
          ? setImgPerc(Math.round(progress))
          : urlType === "autoImgUrl"
          ? setAutoImgPerc(Math.round(progress))
          : setVideoPerc(Math.round(progress));
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.error("Error uploading file:", error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setInputs((prev) => ({
            ...prev,
            [urlType]: downloadURL,
          }));
        });
      }
    );
  };

  useEffect(() => {
    if (video) {
      setVideoPerc(0);
      uploadFile(video, "videoUrl");
    }
  }, [video]);

  useEffect(() => {
    if (img) {
      setImgPerc(0);
      uploadFile(img, "imgUrl");
    }
  }, [img]);

  useEffect(() => {
    if (autoGeneratedImg) {
      setAutoImgPerc(0);
      uploadFile(autoGeneratedImg, "autoImgUrl");
    }
  }, [autoGeneratedImg]);

  const handleUpload = async (e) => {
    e.preventDefault();
    setLoading(true);

    await Promise.all([
      video && uploadFile(video, "videoUrl"),
      img && uploadFile(img, "imgUrl"),
      autoGeneratedImg && uploadFile(autoGeneratedImg, "autoImgUrl"),
    ]);

    const authToken = 'Bearer ' + localStorage.getItem("userToken");

    try {
      const res = await axios.post(
        "/videos",
        { ...inputs, tags, affiliateLink }, // Include affiliateLink in the request body
        {
          headers: {
            'Authorization': authToken,
          },
        }
      );

      setOpen(false);

      if (res.status === 200) {
        navigate(`/video/${res.data._id}`);
      }
    } catch (error) {
      console.error("Error uploading video:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Wrapper>
        <Title>Upload a New Video</Title>
        <VideoContainer>
          <UploadPreview
            video={video}
            setVideo={setVideo}
            img={img}
            setImg={setImg}
            autoGeneratedImg={autoGeneratedImg}
            setAutoGeneratedImg={setAutoGeneratedImg}
            videoPerc={videoPerc}
            imgPerc={imgPerc}
            autoImgPerc={autoImgPerc}
          />
          <DetailsSection>
            <FormSection>
              <InputContainer>
                <Input
                  type="text"
                  id="title"
                  placeholder="Add a title that describes your video"
                  name="title"
                  onChange={handleChange}
                />
                <Label htmlFor="title">Title (required)</Label>
              </InputContainer>
            </FormSection>
            <FormSection>
              <InputContainer>
                <Desc
                  id="desc"
                  placeholder="Tell viewers about your video"
                  name="desc"
                  rows={8}
                  onChange={handleChange}
                />
                <Label htmlFor="desc">Description</Label>
              </InputContainer>
            </FormSection>
            <FormSection>
              <InputContainer>
                <Input
                  type="text"
                  id="tags"
                  placeholder="Separate the tags with commas."
                  name="tags"
                  onChange={handleTags}
                />
                <Label htmlFor="tags">Tags</Label>
              </InputContainer>
            </FormSection>
            <FormSection>
              <InputContainer>
                <Input
                  type="text"
                  id="affiliateLink"
                  placeholder=""
                  name="affiliateLink"
                  onChange={(e) => setAffiliateLink(e.target.value)}
                />
                <Label htmlFor="affiliateLink">Affiliate Link  (optional)</Label>
              </InputContainer>
            </FormSection>
          </DetailsSection>
        </VideoContainer>
        {loading && <p>Uploading in progress...</p>}
        <Button onClick={handleUpload} disabled={loading}>
          Upload
        </Button>
      </Wrapper>
    </Container>
  );
};

export default Upload;
