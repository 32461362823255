import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { format } from "timeago.js";

// Define keyframes for loading animation
const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

// Define your existing styled components
const Container = styled.div`
  width: ${(props) => props.type !== "sm" && "390px"};
  margin-bottom: ${(props) => (props.type === "sm" ? "10px" : "45px")};
  cursor: pointer;
  display: ${(props) => props.type === "sm" && "flex"};
  gap: 1px;
  flex: 1 0 25%;
`;

const Image = styled.img`
  width: 100%;
  height: ${(props) => (props.type === "sm" ? "120px" : "233.95px")};
  background-color: #999;
  border-radius: 20px;
  flex: 1;
`;

const Details = styled.div`
  display: flex;
  margin-top: ${(props) => props.type !== "sm" && "16px"};
  gap: 12px;
  flex: 1;
`;

const ChannelImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #999;
  display: ${(props) => props.type === "sm" && "none"};
`;

const Texts = styled.div``;

const Title = styled.h1`
  font-size: 19px;
  font-weight: 500;
  color: black;
`;

const ChannelName = styled.h2`
  font-size: 15px;
  color: ${({ theme }) => theme.textSoft};
  margin: 9px 0px;
  font-weight: 400;
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  column-gap: 10vw;
  margin-top: 0.7vw;
`;

const Info = styled.div`
  font-size: 14px;
  font-family: "Roboto", "Arial", sans-serif;
  color: ${({ theme }) => theme.textSoft};
  font-weight: 400;
`;

// Define skeleton styled components
const SkeletonContainer = styled.div`
  width: 100%;
  height: ${(props) => (props.type === "sm" ? "120px" : "233.95px")};
  border-radius: 20px;
  background: #cccccc;
  background-image: linear-gradient(to right, #cccccc 0%, #aaaaaa 20%, #cccccc 40%, #cccccc 100%);
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  display: inline-block;
  position: relative;
  animation: ${shimmer} 1.5s infinite linear forwards;
  flex: 1;
`;

const SkeletonDetails = styled.div`
  display: flex;
  margin-top: ${(props) => props.type !== "sm" && "16px"};
  gap: 12px;
  flex: 1;
`;

const SkeletonChannelImage = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #cccccc;
  background-image: linear-gradient(to right, #cccccc 0%, #aaaaaa 20%, #cccccc 40%, #cccccc 100%);
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  display: ${(props) => props.type === "sm" && "none"};
  animation: ${shimmer} 1.5s infinite linear forwards;
`;

const SkeletonText = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "16px")};
  background: #cccccc;
  background-image: linear-gradient(to right, #cccccc 0%, #aaaaaa 20%, #cccccc 40%, #cccccc 100%);
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0")};
  animation: ${shimmer} 1.5s infinite linear forwards;
`;

const SkeletonInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  column-gap: 10vw;
  margin-top: 0.7vw;
`;

// Define the Card component
const Card = ({ type, video, displayProfileInfo, likes, comments, linkClicks, sales }) => {
  const [channel, setChannel] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChannel = async () => {
      try {
        const res = await axios.get(`/channels/${video.channelId}`);
        setChannel(res.data);
      } catch (error) {
        console.error("Error fetching channel data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (video.channelId) {
      fetchChannel();
    } else {
      console.error("video.channelId is undefined");
      setLoading(false);
    }
  }, [video.channelId]);

  return (
    <Container type={type}>
      <Link to={`/video/${video._id}`} style={{ textDecoration: "none" }}>
        {loading ? (
          <SkeletonContainer type={type} />
        ) : (
          <Image type={type} src={video.imgUrl} />
        )}
      </Link>
      {loading ? (
        <SkeletonDetails type={type}>
          <SkeletonChannelImage type={type} />
          <Texts>
            <SkeletonText width="80%" height="20px" />
            {displayProfileInfo ? (
              <SkeletonInfoWrapper>
                <SkeletonText width="50%" />
                <SkeletonText width="50%" />
                <SkeletonText width="50%" />
                <SkeletonText width="50%" />
                <SkeletonText width="50%" />
                <SkeletonText width="50%" />
              </SkeletonInfoWrapper>
            ) : (
              <>
                <SkeletonText width="60%" marginTop="10px" />
                <SkeletonText width="40%" marginTop="10px" />
              </>
            )}
          </Texts>
        </SkeletonDetails>
      ) : channel._id ? (
        <Details type={type}>
          <Link
            to={{
              pathname: `/channel/${channel._id}`,
              state: { channel, video },
            }}
            style={{ textDecoration: "none" }}
          >
            <ChannelImage type={type} src={channel?.imgUrl} />
          </Link>
          <Link to={`/video/${video._id}`} style={{ textDecoration: "none", flex: 1 }}>
            <Texts>
              {displayProfileInfo ? (
                <div>
                  <Title>{video.title}</Title>
                  <InfoWrapper>
                    <Info>{format(video.createdAt)}</Info>
                    <Info>{video.views} views</Info>
                    <Info>{likes} likes</Info>
                    <Info>{comments} comments</Info>
                    <Info>{linkClicks} link clicks</Info>
                    <Info>{sales} sales</Info>
                  </InfoWrapper>
                </div>
              ) : (
                <>
                  <Title>{video.title}</Title>
                  <ChannelName>{channel?.name}</ChannelName>
                  <Info>
                    {video.views} views • {format(video.createdAt)}
                  </Info>
                </>
              )}
            </Texts>
          </Link>
        </Details>
      ) : (
        <p>Error loading channel data.</p>
      )}
    </Container>
  );
};

export default Card;
