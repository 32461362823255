// src/components/BottomNav.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const BottomNavContainer = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5vh;
  background-color: #fff;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  justify-content: space-around;
  align-items: center;
  z-index: 10;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #555;
  text-decoration: none;

  &:hover {
    color: #000;
  }
`;

const BottomNav = () => {
  return (
    <BottomNavContainer>
      <NavItem to="/">
        <HomeIcon />
        <span>Home</span>
      </NavItem>
      <NavItem to="/shorts">
        <VideoLibraryIcon />
        <span>Shorts</span>
      </NavItem>
      <NavItem to="/upload">
        <AddBoxIcon />
        <span>Upload</span>
      </NavItem>
      <NavItem to="/userprofile">
        <AccountCircleIcon />
        <span>Account</span>
      </NavItem>
    </BottomNavContainer>
  );
};

export default BottomNav;
