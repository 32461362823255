import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import ChannelImage from "../img/ChannelImage.jpeg";
import Card2 from "./Card2";
import ShortsCard from "./ShortsCard";
import EditProfilePopup from "./EditProfilePopup";
import Stats from "./Statistics";

const PageWrapper = styled.div`
  background-color: #333;
  height: 100%;
  margin-top: 11vh;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #333;
  width: 100%;
  overflow-x: hidden;
`;

const ProfileContentContainer = styled.div`
  display: flex;
  padding: 20px;
  margin-left: 40px;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  width: 700px;
  background-color: #181818;
  border-radius: 5px;
`;

const DefaultAvatarIcon = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-right: 30px;
  background-image: url(${ChannelImage});
  background-size: cover;
  cursor: pointer;
`;

const UserDetails = styled.div`
  color: white;
  width: 70%;
`;

const ChannelName = styled.span`
  font-weight: bold;
  display: block;
  margin-bottom: 2vh;
  font-size: 23px;
`;

const Username = styled.span`
  display: block;
  margin-bottom: 2vh;
`;

const Description = styled.p`
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({ expanded }) => (expanded ? "unset" : "1")};
  -webkit-box-orient: vertical;
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const TabButton = styled.button`
  background-color: transparent;
  border: none;
  margin-top: 4vh;
  margin-left: 50px;
  color: white;
  cursor: pointer;
  margin-right: 20px;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  border-bottom: ${({ active }) => (active ? "2px solid white" : "none")};
`;

const ContentContainer = styled.div`
  padding: 20px 40px;
  margin-top: 3px;
  background-color: #333;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1px 10px;
  color: #aaa;
  font-weight: bold;
  background-color: #333;
`;

const ColumnHeader = styled.div`
  flex: 1;
  text-align: center;
  &:first-child {
    flex: 3.3; /* Allocate more space for the title */
    text-align: left;
  }
`;

const HistoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ gap }) => gap || "15px"};
  margin-top: 20px;
  width: 100%;
`;

const ChannelProfile = () => {
  const [channelData, setChannelData] = useState({});
  const [expandedDescription, setExpandedDescription] = useState(false);
  const [activeTab, setActiveTab] = useState("videos");
  const [videos, setVideos] = useState([]);
  const [shorts, setShorts] = useState([]);
  const [isEditPopupOpen, setEditPopupOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChannelDetails = async () => {
      setLoading(true);
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (!userData || !userData.ownedChannel) {
          throw new Error("Channel ID not found in local storage");
        }
        const channelId = userData.ownedChannel;
        const response = await axios.get(`/channels/details/${channelId}`);
        const channelDataWithUsername = { ...response.data, username: userData.username };

        console.log("Channel Data Received:", response.data); // Log the data received from the server

        setChannelData(channelDataWithUsername);
      } catch (error) {
        console.error("Error fetching channel details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchChannelDetails();
  }, []);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const channelId = userData.ownedChannel;
        const response = await axios.get(`/videos/${channelId}`);
        setVideos(response.data);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    const fetchShorts = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const channelId = userData.ownedChannel;
        const response = await axios.get(`/shorts/${channelId}`);
        setShorts(response.data);
      } catch (error) {
        console.error("Error fetching shorts:", error);
      }
    };

    if (activeTab === "videos") {
      fetchVideos();
    } else {
      fetchShorts();
    }
  }, [activeTab]);

  const handleAvatarClick = () => {
    setEditPopupOpen(true);
  };

  const handleClosePopup = () => {
    setEditPopupOpen(false);
  };

  const handleSaveProfile = (updatedData) => {
    setChannelData(updatedData);
  };

  const handleVideoDeleted = (videoId) => {
    setVideos((prevVideos) => prevVideos.filter((video) => video._id !== videoId));
  };

  return (
    <PageWrapper>
      <Container>
        <ProfileContentContainer>
          <ProfileContainer>
            <DefaultAvatarIcon onClick={handleAvatarClick} />
            <UserDetails>
              <ChannelName>{channelData.name}</ChannelName>
              <Username>@{channelData.username}</Username>
              <Description expanded={expandedDescription}>
                {channelData.description}
              </Description>
            </UserDetails>
          </ProfileContainer>
          <Stats
            subscribers={channelData.subscribers?.length || 0}
            videos={videos?.length || 0}
            shorts={shorts?.length || 0}
            joined={new Date(channelData.createdAt).toLocaleDateString()}
            loading={loading}
          />
        </ProfileContentContainer>
        <TabContainer>
          <TabButton
            active={activeTab === "videos"}
            onClick={() => setActiveTab("videos")}
          >
            Videos
          </TabButton>
          <TabButton
            active={activeTab === "shorts"}
            onClick={() => setActiveTab("shorts")}
          >
            Shorts
          </TabButton>
          <TabButton
            active={activeTab === "earnings"}
            onClick={() => setActiveTab("earnings")}
          >
            Earnings
          </TabButton>
        </TabContainer>
        <ContentContainer>
          {activeTab === "videos" && (
            <HistoryContainer gap="20px">
              {videos.map((video) => (
                <Card2 key={video._id} video={video} />
              ))}
            </HistoryContainer>
          )}
          {activeTab === "shorts" && (
            <HistoryContainer gap="10px">
              {shorts.map((short) => (
                <ShortsCard key={short._id} short={short} />
              ))}
            </HistoryContainer>
          )}
          {activeTab === "earnings" && (
            <>
              <HeaderRow>
                <ColumnHeader>Month</ColumnHeader>
                <ColumnHeader>Earnings</ColumnHeader>
                <ColumnHeader>Ad Revenue</ColumnHeader>
                <ColumnHeader>Memberships</ColumnHeader>
              </HeaderRow>
              {/* Replace with actual earnings data */}
              <HistoryContainer gap>
                {/* Example earnings data */}
                <div>
                  <ColumnHeader>June</ColumnHeader>
                  <ColumnHeader>$500</ColumnHeader>
                  <ColumnHeader>$300</ColumnHeader>
                  <ColumnHeader>$200</ColumnHeader>
                </div>
              </HistoryContainer>
            </>
          )}
        </ContentContainer>
        {isEditPopupOpen && (
          <EditProfilePopup
            channelData={channelData}
            onClose={handleClosePopup}
            onSave={handleSaveProfile}
          />
        )}
      </Container>
    </PageWrapper>
  );
};

export default ChannelProfile;
