// Define a theme for typography sizes
const theme = {
    typography: {
      heading: '1.5vw', // Adjust as needed
      subtitle: '1vw', // Adjust as needed
      h6: '1.1vw', // Adjust as needed
      h7: '1vw', 
    },
  };

export default theme;