import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Card from "../components/Card";
import SkeletonLoader from "../components/SkeletonLoader"; // Import the SkeletonLoader

const SavedVideosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #333;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 5vh;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 20px; /* Add padding to the container */
`;

const CardsContainer = styled.div`
  margin-left: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 2vw;
`;

const Message = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: white;
  font-weight: bold;
`;

const SavedVideos = () => {
  const [savedVideos, setSavedVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchSavedVideos = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (!userData || !userData._id) {
          throw new Error("User ID not found in local storage");
        }
        const userId = userData._id;

        console.log("Fetching saved videos...");
        const endpoint = `/videos/${userId}/saved`;
        const response = await axios.get(endpoint);

        console.log("Response:", response);

        if (Array.isArray(response.data)) {
          setSavedVideos(response.data);
        } else if (response && response.data && response.data.message) {
          setErrorMessage(response.data.message);
        } else {
          throw new Error("Invalid data format received from server");
        }
      } catch (error) {
        console.error("Error fetching saved videos:", error);
        if (error.response) {
          console.log("Server response:", error.response.data);
          setErrorMessage(error.response.data.message || "Error fetching saved videos. Please try again later.");
        } else {
          setErrorMessage("Error fetching saved videos. Please try again later.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchSavedVideos();
  }, []);

  if (loading) {
    return <SkeletonLoader />;
  }

  return (
    <SavedVideosContainer>
      {errorMessage ? (
        <Message>{errorMessage}</Message>
      ) : (
        <CardsContainer>
          {savedVideos.map((video) => (
            <Card
              key={video._id}
              video={video}
            />
          ))}
        </CardsContainer>
      )}
    </SavedVideosContainer>
  );
};

export default SavedVideos;
