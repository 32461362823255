import axios from "axios";
import React from "react";
import styled from "styled-components";

// Axios configuration to include credentials
axios.defaults.withCredentials = true;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:rgba(0, 0, 0, 0.7);;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
`;

const Modal = styled.div`
  background-color:#333;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: flex;
  color:#F8F8F8;
  flex-direction: column;
  gap: 20px;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const ModalButton = styled.button`
  background-color: #444;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #555;
  }
`;

const DeleteConfirmationModal = ({ videoId, onCancel, onDeleteSuccess }) => {
  const handleDelete = async () => {
    try {
      await axios.delete(`/videos/${videoId}/delete`, { withCredentials: true });
      onDeleteSuccess();
    } catch (error) {
      console.error("Error deleting video:", error);
    }
  };

  return (
    <ModalOverlay>
      <Modal>
        <p>Are you sure you want to delete this video forever?</p>
        <ModalButtons>
          <ModalButton onClick={onCancel}>Cancel</ModalButton>
          <ModalButton onClick={handleDelete}>Delete</ModalButton>
        </ModalButtons>
      </Modal>
    </ModalOverlay>
  );
};

export default DeleteConfirmationModal;
