import React from "react";
import Video from "./Video";

const DummyVideoPage = () => {
  // Define dummy video data
  const dummyVideo = {
    _id: "video123",
    videoUrl: "https://www.youtube.com/watch?v=Ma7J9RqBgzw&ab_channel=BassBangers", // Ensure videoUrl is defined
    title: "Dummy Video Title can be long or can be short for testing",
    views: 1000,
    createdAt: new Date(),
    likes: 13,
    dislikes: [],
    desc: "Description of the dummy video short or long",
    userId: "user123",
    tags: ["tag1", "tag2"]
  };

  // Simulate current user data
  const currentUser = {
    _id: "user123",
    subscribedUsers:[]
  };

  return <Video currentUser={currentUser} currentVideo={dummyVideo} />;
};

export default DummyVideoPage;
