import React, { useState, useEffect, useCallback } from "react";
import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
`;

const Skeleton = styled.div`
  background: linear-gradient(to right, #f3f3f3 4%, #e8e8e8 25%, #f3f3f3 36%);
  background-size: 1000px 100%;
  animation: ${shimmer} 1.5s infinite linear;
`;

const slideStyles = {
  width: "100%",
  height: "100%",
  borderRadius: "0",
  objectFit: "cover",
  position: "absolute",
  transition: "right 0.5s ease",
};

const sliderStyles = {
  position: "relative",
  height:"100%",
  overflow: "hidden",
  width: "100%",
};

const dotsContainerStyles = {
  display: "flex",
  justifyContent: "center",
  marginTop: "5px",
};

const dotStyle = {
  margin: "0 3px",
  cursor: "pointer",
  fontSize: "20px",
};

const SlideShow = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const goToNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
  }, [slides]);

  useEffect(() => {
    setLoading(true);
    const interval = setInterval(() => {
      setLoading(false);
      goToNext();
    }, 4000);

    return () => clearInterval(interval);
  }, [goToNext]);

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const currentSlide = slides[currentIndex];

  if (!currentSlide || !currentSlide.url || loading) {
    return (
      <div style={{ ...sliderStyles, backgroundColor: "transparent" }}>
        <div style={{ ...slideStyles, backgroundColor: "#e0e0e0" }}>
          <Skeleton />
        </div>
      </div>
    );
  }

  return (
    <div style={sliderStyles}>
      {slides.map((slide, slideIndex) => (
        <div
          key={slideIndex}
          style={{
            ...slideStyles,
            right: `${(currentIndex - slideIndex) * 100}%`,
          }}
        >
          <img
            src={slide.url}
            alt={slide.url}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "10px",
              objectFit: "cover",
            }}
          />
        </div>
      ))}
      <div style={dotsContainerStyles}>
        {slides.map((slide, slideIndex) => (
          <div
            style={dotStyle}
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
          >
            ●
          </div>
        ))}
      </div>
    </div>
  );
};

export default SlideShow;