// components/HomeSkeletonLoader.js
import React from 'react';
import styled from 'styled-components';

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
  width: 100%;
  padding-top: 2vh;
`;

const SkeletonSlideshow = styled.div`
  width: 100%;
  height: 80vh;
  background: #ddd;
  margin-bottom: 2vh;
  @media (max-width: 768px) {
    height: 50vh;
  }
`;

const SkeletonSection = styled.div`
  width: 80%;
  height: 2.5vw;
  background: #ddd;
  margin: 1vh 0;
  border-radius: 4px;
  @media (max-width: 768px) {
    height: 4vw;
  }
`;

const SkeletonCard = styled.div`
  width: 20vw;
  height: 12vw;
  background: #ddd;
  margin: 1vw;
  border-radius: 4px;
  @media (max-width: 768px) {
    width: 40vw;
    height: 20vw;
  }
`;

const SkeletonShorts = styled.div`
  width: 20vw;
  height: 10vw;
  background: #ddd;
  margin: 1vw;
  border-radius: 4px;
  @media (max-width: 768px) {
    width: 40vw;
    height: 20vw;
  }
`;

const HomeSkeletonLoader = () => {
  return (
    <SkeletonContainer>
      <SkeletonSlideshow />
      <SkeletonSection style={{ width: '60%', height: '3vw' }} />
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {[...Array(3)].map((_, index) => (
          <SkeletonCard key={index} />
        ))}
      </div>
      <SkeletonSection style={{ width: '60%', height: '3vw' }} />
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {[...Array(5)].map((_, index) => (
          <SkeletonShorts key={index} />
        ))}
      </div>
    </SkeletonContainer>
  );
};

export default HomeSkeletonLoader;
