import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Card4 from "../components/Card4";

const Wrapper = styled.div`
  background-color:#181818;
  min-height: 100vh; /* Ensure the wrapper covers the full viewport height */
  align-item:center;
   `;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top:70px;
  padding-left: 50px;
`;

const Search = () => {
  const [videos, setVideos] = useState([]);
  const query = useLocation().search;

  useEffect(() => {
    const fetchVideos = async () => {
      const res = await axios.get(`/videos/search${query}`);
      setVideos(res.data);
    };
    fetchVideos();
  }, [query]);

  return (
    <Wrapper>
      <Container>
        {videos.map(video => (
          <Card4 key={video._id} video={video} />
        ))}
      </Container>
    </Wrapper>
  );
};

export default Search;
