import React, { useState } from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 80%;
`;

const ModalHeader = styled.h2`
  margin-bottom: 10px;
`;

const ModalContent = styled.div`
  margin-bottom: 20px;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  background: ${({ primary }) => (primary ? '#3498db' : '#e74c3c')};
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    opacity: 0.9;
  }
`;

const TextArea = styled.textarea`
  width: 95%;
  height: 80px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const RadioGroup = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
`;

const RadioButton = styled.label`
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 14px;

  input {
    margin-right: 5px;
  }
`;

const OptionalText = styled.p`
  font-size: 12px;
  color: gray;
  margin-top: -8px;
  margin-bottom: 10px;
`;

const Modal = ({ show, onClose, onSubmit, title }) => {
  const [experience, setExperience] = useState('');
  const [recommend, setRecommend] = useState('');
  const [improvements, setImprovements] = useState('');

  const handleClose = () => {
    setExperience('');
    setRecommend('');
    setImprovements('');
    onClose();
  };

  const handleSubmit = () => {
    onSubmit({ experience, recommend, improvements });
    handleClose();
  };

  if (!show) return null;

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>{title}</ModalHeader>
        <ModalContent>
          <TextArea
            placeholder="Share your experience"
            value={experience}
            onChange={(e) => setExperience(e.target.value)}
            required
          />
          <TextArea
            placeholder="How can we improve? (Optional)"
            value={improvements}
            onChange={(e) => setImprovements(e.target.value)}
          />
          <OptionalText>(Optional)</OptionalText>
          <h3>Would you recommend this product?</h3>
          <RadioGroup>
            <RadioButton>
              <input
                type="radio"
                value="Yes"
                checked={recommend === 'Yes'}
                onChange={() => setRecommend('Yes')}
              />
              Yes
            </RadioButton>
            <RadioButton>
              <input
                type="radio"
                value="No"
                checked={recommend === 'No'}
                onChange={() => setRecommend('No')}
              />
              No
            </RadioButton>
            <RadioButton>
              <input
                type="radio"
                value="Not sure"
                checked={recommend === 'Not sure'}
                onChange={() => setRecommend('Not sure')}
              />
              Not sure
            </RadioButton>
          </RadioGroup>
        </ModalContent>
        <ModalActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button primary onClick={handleSubmit}>
            Submit
          </Button>
        </ModalActions>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;
