import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import furniture from '../img/furniture.PNG';
import Kitchen from '../img/Kitchen.PNG';

const MyCardContainer = styled.div`
  position: relative;
  width: 29vw;
  border-radius: 2px;
  border:9px;
  border:1px solid #cccccc;
  background-color: #f7f7f7;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImagesRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ImageWrapper = styled.div`
  width: 42%;
  margin: 2.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const MyCardImage = styled.img`
  width: 100%;
  height: 80%;
  object-fit: cover;
`;

const ImageDescription = styled.p`
  font-size: 12px;
  color: black;
  margin-top: 0.5vw;
`;

const MyCardContent = styled.div`
  position: relative;
  background-color: transparent;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MyCardDescription = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  margin-bottom: 1vw;
`;

// Skeleton animation for loading
const shimmer = keyframes`
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
`;

const Skeleton = styled.div`
  background: linear-gradient(to right, #f3f3f3 4%, #e8e8e8 25%, #f3f3f3 36%);
  background-size: 1000px 100%;
  animation: ${shimmer} 1.5s infinite linear;
`;

const MyCardLoadingSkeleton = styled(MyCardContainer)`
  ${Skeleton} {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`;

const HomeContainer = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <MyCardLoadingSkeleton>
          <Skeleton />
        </MyCardLoadingSkeleton>
      ) : (
        <MyCardContainer>
          <MyCardContent>
            <MyCardDescription style={{ color: 'black' }}>Home</MyCardDescription>
            <ImagesRow>
              <ImageWrapper>
                <MyCardImage src={furniture} alt="Furniture" />
                <ImageDescription>Home</ImageDescription>
              </ImageWrapper>
              <ImageWrapper>
                <MyCardImage src={Kitchen} alt="Kitchen" />
                <ImageDescription>Kitchen </ImageDescription>
              </ImageWrapper>
            </ImagesRow>
          </MyCardContent>
        </MyCardContainer>
      )}
    </>
  );
};

export default HomeContainer;
