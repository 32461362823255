import React from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
`;

const Modal = styled.div`
  background-color: #333;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: flex;
  color: #f8f8f8;
  flex-direction: column;
  gap: 20px;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const ModalButton = styled.button`
  background-color: #444;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #555;
  }
`;

const SaveConfirmationModal = ({ onCancel, onConfirmSave }) => {
  return (
    <ModalOverlay>
      <Modal>
        <p>Are you sure you want to save these changes?</p>
        <ModalButtons>
          <ModalButton onClick={onCancel}>Cancel</ModalButton>
          <ModalButton onClick={onConfirmSave}>Save</ModalButton>
        </ModalButtons>
      </Modal>
    </ModalOverlay>
  );
};

export default SaveConfirmationModal;
