import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import axios from "axios";
import { loginFailure, loginStart, loginSuccess } from "../redux/userSlice";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: white;
  margin-top: 56px;
  width: 100%;
  height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 20px 30px;
  gap: 10px;
  width: 100%;
  max-width: 400px;
  height: auto;
  max-height: 600px;
  overflow: hidden;
  position: relative;
`;

const Title = styled.h1`
  font-size: 24px;
  color: black;
`;

const SubTitle = styled.h2`
  font-size: 23px;
  font-weight: 300;
  color: black;
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.soft};
  border-radius: 3px;
  border-color: ${({ glowRed }) => (glowRed ? "red" : "grey")};
  padding: 14px;
  background-color: transparent;
  width: 100%;
  color: black;
  font-size: 17px;
  ${({ glowRed }) =>
    glowRed &&
    css`
      box-shadow: 0 0 5px red;
    `}
`;

const Button = styled.button`
  border-radius: 3px;
  border: none;
  padding: 16px 50px;
  font-weight: 500;
  cursor: pointer;
  background-color: blue;
  color: white;
  font-size: 15px;

  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  }
`;

const PasswordRequirementsPopup = styled.div`
  position: absolute;
  bottom: 17px; /* Adjust position as needed */
  left: 0;
  background-color: white;
  padding: 10px;
  border: 1.5px solid grey;
  width: 80%;
`;

const RequirementItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const RequirementText = styled.span`
  margin-left: 10px;
  color:black;
`;

const SignUpPage = () => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState(""); // Added state for username
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
  const [glowPassword, setGlowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (!name || !username || !email || !password || !confirmPassword) {
      console.error("All fields are required");
      return;
    }

    if (!validatePassword(password)) {
      setShowPasswordRequirements(true);
      setGlowPassword(true);
      return;
    }

    if (password !== confirmPassword) {
      console.error("Password and confirmation do not match");
      return;
    }

    dispatch(loginStart());
    try {
      const res = await axios.post("/auth/signup", { name, username, email, password }); // Include username in the request
      const { token, _id, role } = res.data;

    localStorage.setItem("userToken", token);
    const userData = {
      _id,
      token,
      username,
      role
    };
    localStorage.setItem("userData", JSON.stringify(userData));


      dispatch(loginSuccess(res.data));
      navigate("/");
    } catch (err) {
      console.error("Sign-up error:", err);
      dispatch(loginFailure());
    }
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,]).{6,}$/;
    return passwordRegex.test(password);
  };

  return (
    <Container>
      <Wrapper>
        <Title>Sign Up</Title>
        <SubTitle>Create your account</SubTitle>
        <Input
          placeholder="Name"
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          placeholder="Username"
          onChange={(e) => setUsername(e.target.value)}
        />
        <Input
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="password"
          glowRed={glowPassword}
          placeholder="Password"
          onFocus={() => setShowPasswordRequirements(true)}
          onBlur={() => setShowPasswordRequirements(false)}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Confirm password"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button onClick={handleSignUp}>Sign up</Button>
        {showPasswordRequirements && (
          <PasswordRequirementsPopup>
          <RequirementItem>
            <input type="checkbox" checked={validatePassword(password)} readOnly />
            <RequirementText>At least 6 characters long</RequirementText>
          </RequirementItem>
          <RequirementItem>
            <input type="checkbox" checked={/[0-9]/.test(password)} readOnly />
            <RequirementText>At least one number</RequirementText>
          </RequirementItem>
          <RequirementItem>
            <input type="checkbox" checked={/[A-Z]/.test(password)} readOnly />
            <RequirementText>At least one capital letter</RequirementText>
          </RequirementItem>
          <RequirementItem>
            <input type="checkbox" checked={/[!@#$%^&*()_+}{"':;?/>.<,]/.test(password)} readOnly />
            <RequirementText>At least one symbol</RequirementText>
          </RequirementItem>
        </PasswordRequirementsPopup>
        )}
      </Wrapper>
    </Container>
  );
};
 export default SignUpPage;
