import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { format } from "timeago.js";
import { FaEllipsisV, FaEdit, FaTrash } from "react-icons/fa";
import DeleteConfirmationModal from "./DeleteConfirmationModal"; // Import the delete modal component
import VideoEditModal from "./VideoEditModal"; // Import the edit modal component

const Container = styled.div`
  width: ${(props) => props.type !== "sm" && "350px"};
  margin-bottom: ${(props) => (props.type === "sm" ? "10px" : "45px")};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative; /* For positioning the popup */
`;

const ImageLink = styled(Link)`
  display: block;
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: ${(props) => (props.type === "sm" ? "160px" : "220px")};
  background-color: #999;
  border-radius: 20px;
`;

const Details = styled.div`
  display: flex;
  margin-top: ${(props) => props.type !== "sm" && "16px"};
  gap: 12px;
  align-items: center;
`;

const ChannelImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #999;
  display: ${(props) => props.type === "sm" && "none"};
`;

const Texts = styled.div`
  flex: 1;
`;

const OptionsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative; /* For positioning the popup */
`;

const Title = styled.h1`
  font-size: 19px;
  font-weight: 500;
  color: white;
`;

const ChannelName = styled.h2`
  font-size: 15px;
  color: ${({ theme }) => theme.textSoft};
  margin: 9px 0px;
  font-weight: 400;
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  column-gap: 10vw;
  margin-top: 0.7vw;
`;

const Info = styled.div`
  font-size: 14px;
  font-family: "Roboto", "Arial", sans-serif;
  color: ${({ theme }) => theme.textSoft};
  font-weight: 400;
`;

const Popup = styled.div`
  position: absolute;
  top: 100%; /* Align the top edge of the popup with the bottom edge of the OptionsButton */
  right: 0; /* Align the right edge of the popup with the right edge of the OptionsButton */
  background-color: #333;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PopupButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;

  &:hover {
    color: #bbb;
  }
`;

const Card2 = ({ type, video, displayProfileInfo, likes, comments, linkClicks, sales }) => {
  const [channel, setChannel] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for showing the delete modal
  const [showEditModal, setShowEditModal] = useState(false); // State for showing the edit modal
  const containerRef = useRef(null);

  useEffect(() => {
    const fetchChannel = async () => {
      const res = await axios.get(`/users/find/${video.userId}`);
      setChannel(res.data);
    };
    fetchChannel();
  }, [video.userId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  const openDeleteModal = () => {
    setShowPopup(false); // Close the initial popup
    setShowDeleteModal(true); // Show the delete confirmation modal
  };

  const openEditModal = () => {
    setShowPopup(false); // Close the initial popup
    setShowEditModal(true); // Show the edit modal
  };

  const handleDeleteSuccess = () => {
    setShowDeleteModal(false);
    // Add any additional logic needed upon successful deletion
  };

  const handleEditSuccess = (updatedVideo) => {
    setShowEditModal(false);
    // Optionally, you can add logic to update the video details in the UI here
  };

  return (
    <Container type={type} ref={containerRef}>
      <ImageLink to={`/video/${video._id}`}>
        <Image type={type} src={video.imgUrl} />
      </ImageLink>
      <Details type={type}>
        <ChannelImage type={type} src={channel?.imgUrl} />
        <Texts>
          {displayProfileInfo ? (
            <div>
              <Title>{video.title}</Title>
              <InfoWrapper>
                <Info>{format(video.createdAt)}</Info>
                <Info>{video.views} views</Info>
                <Info>{likes} likes</Info>
                <Info>{comments} comments</Info>
              </InfoWrapper>
            </div>
          ) : (
            <>
              <Title>{video.title}</Title>
              <ChannelName>{channel?.name}</ChannelName>
              <Info>
                {video.views} views • {format(video.createdAt)}
              </Info>
            </>
          )}
        </Texts>
        <OptionsButton onClick={() => setShowPopup((prev) => !prev)}>
          <FaEllipsisV color="white" />
          {showPopup && (
            <Popup>
              <PopupButton onClick={openEditModal}>
                <FaEdit /> Edit
              </PopupButton>
              <PopupButton onClick={openDeleteModal}>
                <FaTrash /> Delete
              </PopupButton>
            </Popup>
          )}
        </OptionsButton>
      </Details>
      {showDeleteModal && (
        <DeleteConfirmationModal
          videoId={video._id}
          onDeleteSuccess={handleDeleteSuccess}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
      {showEditModal && (
        <VideoEditModal
          video={video}
          onEditSuccess={handleEditSuccess}
          onCancel={() => setShowEditModal(false)}
        />
      )}
    </Container>
  );
};

export default Card2;
