import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

// Define keyframes for loading animation
const shimmer = keyframes`
  0% {
    background-position: -46.8vw 0;
  }
  100% {
    background-position: 46.8vw 0;
  }
`;

// Define your existing styled components
const OuterContainer = styled.div`
  display: flex;
  margin-right: 0.1vw;
  margin-bottom: 2vh;
`;

const Container = styled.div`
  width: 16vw;
  height: 52vh;
  cursor: pointer;
  background-color: grey;
  border-radius: 1vw;
  margin-right: 0.2vw;

  @media (max-width: 768px) {
    width: 42vw;
    height: 31vh;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1vw;

  @media (max-width: 768px) {
    height: 100%;
  }
`;

const Details = styled.div`
  padding: .1vh .1vw 0 .1vw;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: .2vh;
`;

const Title = styled.h3`
  font-size: 1.9vw;
  line-height: 1.2;
  color: ${({ theme }) => theme.text};
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Arial", sans-serif;
  display: -webkit-box;
  -webkit-box-orient: vertical;
 
  overflow: hidden;
  -webkit-line-clamp: 2;

  @media (max-width: 768px) {
    font-size: 3.5vw;
    width: 40vw;
  }
`;

const Views = styled.div`
  font-size: 1.2vw;
  color: ${({ theme }) => theme.textSoft};

  @media (max-width: 768px) {
    font-size: 2.5vw;
  }
`;

const formatViews = (views) => {
  if (views && views >= 1000) {
    const formattedViews = (views / 1000).toFixed(1);
    return formattedViews.endsWith('.0') ? `${Math.floor(formattedViews)}k` : `${formattedViews}k`;
  }
  return views?.toString() || '0';
};

// Define skeleton styled components
const SkeletonContainer = styled.div`
  width: 16vw;
  height: 52vh;
  border-radius: 1vw;
  background: #aaaaaa;
  background-image: linear-gradient(to right, #aaaaaa 0%, #888888 20%, #aaaaaa 40%, #aaaaaa 100%);
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  display: inline-block;
  position: relative;
  animation: ${shimmer} 1.5s infinite linear forwards;

  @media (max-width: 768px) {
    width: 45vw;
    height: 30vh;
  }
`;

const SkeletonThumbnail = styled.div`
  width: 100%;
  height: 30vh;
  border-radius: 1vw;
  background: #aaaaaa;
  background-image: linear-gradient(to right, #aaaaaa 0%, #888888 20%, #aaaaaa 40%, #aaaaaa 100%);
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  animation: ${shimmer} 1.5s infinite linear forwards;

  @media (max-width: 768px) {
    height: 22vh;
  }
`;

const SkeletonDetails = styled.div`
  padding: .1vh .1vw 0 .1vw;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8vh;
`;

const SkeletonText = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "1.6vh")};
  background: #aaaaaa;
  background-image: linear-gradient(to right, #aaaaaa 0%, #888888 20%, #aaaaaa 40%, #aaaaaa 100%);
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0")};
  animation: ${shimmer} 1.5s infinite linear forwards;
`;

const ShortsCard = ({ short }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating a loading delay
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <OuterContainer>
        <SkeletonContainer>
          <SkeletonThumbnail />
        </SkeletonContainer>
        <SkeletonDetails>
          <SkeletonText width="80%" height="2vh" />
          <SkeletonText width="40%" marginTop="1vh" />
        </SkeletonDetails>
      </OuterContainer>
    );
  }

  if (!short || !short._id) {
    return null;
  }

  return (
    <OuterContainer>
      <Link to={`/shorts/${short._id}`} style={{ textDecoration: 'none' }}>
        <Container>
          <Thumbnail src={short.thumbnail} alt="" />
        </Container>
        <Details>
          <Title>{short.Shortstitle}</Title>
          <Views>{formatViews(short.Shortsviews)} views</Views>
        </Details>
      </Link>
    </OuterContainer>
  );
};

export default ShortsCard;
